/**
 * Created by Rakesh Peela
 * Date: 14-Nov-2019
 * Time: 2:30 PM
 */

import {ButtonBase, Grid, IconButton, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import styled from 'styled-components';
import FormSelect from "../../../../../../../../../../components/reusable/MaterialUi/FormSelect";
import MultiSelect from "../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {EventAttributeQuery} from "../../../../../../../../../../components/reusable/QueryBuilder";
import {
    APX_FEATURES,
    CLIENT_EVENTS,
    CONDITIONS_ENUM,
    LOGICAL_OPERATORS,
    MESSAGE_ENUM,
    MESSAGE_EVENTS
} from "../../../../../../../../../../constants";
import ClassicCard from "../../ClassicCard";
import FieldDescription from "./FieldDescription";

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT},
    {label: "≥", value: LOGICAL_OPERATORS.GTE},
    {label: "<", value: LOGICAL_OPERATORS.LT},
    {label: "≤", value: LOGICAL_OPERATORS.LTE},
    {label: "=", value: LOGICAL_OPERATORS.EQ},
    {label: "!=", value: LOGICAL_OPERATORS.NEQ}
];

class CountConfigModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            countConfig: {count = 1, operator = LOGICAL_OPERATORS.GTE},
            handleUpdateCondition
        } = this.props;
        return (
            <Grid container spacing={8} alignItems={"center"}>
                <Grid item xs>
                    <MultiSelect
                        options={Operators}
                        label={"Operator"}
                        value={operator}
                        single
                        handleChange={(op) => handleUpdateCondition({
                            count_config: {
                                operator: op,
                                count
                            }
                        })}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        label="Count"
                        value={count}
                        onChange={e => handleUpdateCondition({
                            count_config: {
                                operator,
                                count: Number(e.target.value)
                            }
                        })}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

class AppEventContextModule extends React.Component {
    render() {
        const {condition: {details: {name}, details, count_config}, handleUpdateCondition, disabled} = this.props;
        return (
            // TODO:<R> Rewrite this
            <div>
                <EventAttributeQuery
                    {...this.props}
                    {...details}
                    disabled={disabled}
                    handleUpdate={details => handleUpdateCondition({
                        details
                    })}
                />
                {
                    name
                    && <Grid container spacing={16}>
                        <Grid item xs={3}/>
                        <Grid item>
                            <CountConfigModule
                                countConfig={{...count_config}}
                                handleUpdateCondition={handleUpdateCondition}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

const CLIENT_EVENTS_FOR_APP = {
    "d831634e11cf6c424b817e22761710e2": {
        "FeedLoadedClient": {},
        "Bottom Nav Selection": {
            "selection": "string",
        },
        "FeedCampaignButtonClicked": {
            "sportName": "string",
        }
    },
    "20ffd8ea-2617-45bf-be5a-04be9e0e4657": {
        "ButtonClicked": {},
        "SymbolButtonClicked": {
            "rank": "string",
            "kind": "string",
        }
    },
};

class ClientContextModule extends React.Component {
    constructor(props) {
        super(props);
        const {condition: {details: {additional_info = {}}},} = this.props;
        this.state = {
            currentAttributeValue: Object.keys(additional_info).length > 0 ? additional_info[Object.keys(additional_info)[0]]["val"] : "",
        }
    }

    hasCustomClientEvents = () => {
        const {appState: {app: {features = []}}} = this.props;
        return features.includes(APX_FEATURES.APX_CLIENT_EVENTS);
    };

    getOptionsForClientEvents = () => {
        let {appState: {app: {app_id, client_events = {}}}} = this.props;
        if (Object.keys(client_events).length === 0) {
            if (Object.keys(CLIENT_EVENTS_FOR_APP).includes(app_id)) {
                client_events = CLIENT_EVENTS_FOR_APP[app_id];
            }
        }
        if (!this.hasCustomClientEvents()) {
            return Object.values(CLIENT_EVENTS);
        }
        return [...Object.values(CLIENT_EVENTS), ...Object.keys(client_events)];
    };

    getAttributesForClientEvents = (event_name) => {
        let {appState: {app: {app_id, client_events = {}}}} = this.props;
        if (Object.keys(client_events).length === 0) {
            if (Object.keys(CLIENT_EVENTS_FOR_APP).includes(app_id)) {
                client_events = CLIENT_EVENTS_FOR_APP[app_id];
            }
        }

        if (Object.keys(client_events).includes(event_name)) {
            if (Object.keys(client_events[event_name]).length > 0) {
                return Object.keys(client_events[event_name])
            }
        }
        return [];
    };

    getCEType = (event, attribute) => {
        const {appState: {app: {client_events = {}}}} = this.props;
        if (Object.keys(client_events).includes(event)) {
            if (Object.keys(client_events[event]).includes(attribute))
                return client_events[event][attribute];
        }
        return "s";
    };

    //TODO: Fix this complex Object accessing
    render() {
        const {condition: {details: {name, additional_info = {}}, details, count_config}, handleUpdateCondition} = this.props;
        const {currentAttributeValue} = this.state;
        const attributes = this.getAttributesForClientEvents(name);
        return <Grid container spacing={8} alignItems={"center"}>
            <Grid item xs={4} md={4}>
                <MultiSelect
                    options={this.getOptionsForClientEvents().map(o => ({label: o, value: o}))}
                    label={"Select Client Event"}
                    value={name}
                    single
                    handleChange={(client_event) => {
                        this.setState({
                            currentAttributeValue: ""
                        })
                        handleUpdateCondition({
                            details: {
                                additional_info: {},
                                name: client_event
                            }
                        })
                    }}
                    style={{marginRight: 8}}
                />
            </Grid>
            {
                this.hasCustomClientEvents() && name && attributes.length > 0
                && <>
                    <Grid item xs={12} md={8}>
                        <Grid container alignItems={"center"} spacing={16}>
                            <Grid item xs={12} md={5}>
                                <FormSelect
                                    options={attributes}
                                    value={Object.keys(additional_info).length > 0 && Object.keys(additional_info)[0]}
                                    label="Select Attribute"
                                    handleUpdate={
                                        (attribute_name) => {
                                            this.setState({
                                                currentAttributeValue: this.getCEType(name, attribute_name) !== "boolean" ? "" : "false"
                                            })
                                            handleUpdateCondition({
                                                details: {
                                                    ...details,
                                                    additional_info: {
                                                        [attribute_name]: {
                                                            "op": LOGICAL_OPERATORS.EQ,
                                                            "t": this.getCEType(name, attribute_name),
                                                            "val": ""
                                                        }
                                                    },
                                                    name,
                                                }
                                            })
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <MultiSelect
                                    single
                                    options={[...Operators, {
                                        label: "ℝegex",
                                        value: "R"
                                    }].filter(x => {
                                        return (Object.keys(additional_info).length > 0) && this.getCEType(name, Object.keys(additional_info)[0]) === "boolean" ? x.value === LOGICAL_OPERATORS.EQ : true
                                    })}
                                    value={Object.keys(additional_info).length > 0 ? additional_info[Object.keys(additional_info)[0]]["op"] : LOGICAL_OPERATORS.GTE}
                                    label="Condition"
                                    handleChange={
                                        (operator) => {
                                            const info = Object.keys(additional_info)[0];
                                            handleUpdateCondition({
                                                details: {
                                                    ...details,
                                                    additional_info: {
                                                        [info]: {
                                                            "op": operator,
                                                            "t": this.getCEType(name, info),
                                                            "val": ""
                                                        }
                                                    },
                                                    name,
                                                }
                                            })
                                        }
                                    }
                                    margin="dense"
                                />
                            </Grid>
                            {
                                (Object.keys(additional_info).length > 0)
                                && <Grid item xs={12} md={5}>
                                    {
                                        this.getCEType(name, Object.keys(additional_info)[0]) === "boolean"
                                            ? (
                                                <FormSelect
                                                    options={[{label: "true", key: "true"}, {label: "false", key: "false"}]}
                                                    value={Object.keys(additional_info).length > 0 ? String(additional_info[Object.keys(additional_info)[0]]["val"]) : false}
                                                    label="Value"
                                                    handleUpdate={
                                                        (bool) => {
                                                            const info = Object.keys(additional_info)[0];
                                                            this.setState({
                                                                currentAttributeValue: bool !== "false"
                                                            })
                                                            handleUpdateCondition({
                                                                details: {
                                                                    ...details,
                                                                    additional_info: {
                                                                        [info]: {
                                                                            "op": additional_info[info]["op"],
                                                                            "t": this.getCEType(name, info),
                                                                            "val": (bool.toLowerCase()) !== "false"
                                                                        }
                                                                    },
                                                                    name,
                                                                }
                                                            })
                                                        }
                                                    }
                                                    margin="dense"
                                                />
                                            ) : (
                                                <TextField
                                                    required
                                                    label="Value"
                                                    value={currentAttributeValue}
                                                    onChange={e => {
                                                        const {value} = e.target;
                                                        const info = Object.keys(additional_info)[0];
                                                        this.setState({
                                                            currentAttributeValue: value,
                                                        }, () => {
                                                            handleUpdateCondition({
                                                                details: {
                                                                    ...details,
                                                                    additional_info: {
                                                                        [info]: {
                                                                            "op": additional_info[info]["op"],
                                                                            "t": this.getCEType(name, info),
                                                                            "val": value
                                                                        }
                                                                    },
                                                                    name,
                                                                }
                                                            })
                                                        })
                                                    }}
                                                    margin="dense"
                                                />
                                            )
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={4} md={4}> {/* Just dummy grid item to push to right*/}
                    </Grid>
                </>
            }
            <Grid item xs={3} md={3}>
                {name && <CountConfigModule
                    countConfig={{...count_config}}
                    handleUpdateCondition={handleUpdateCondition}
                />}
            </Grid>
        </Grid>;
    }
}

const getMessagesListFromType = (messageType, messages) => {
    if (messageType === MESSAGE_EVENTS.INLINE_SHOWN || messageType === MESSAGE_EVENTS.INLINE_DISMISSED) {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_LINE));
    } else {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_APP));
    }
};

const MESSAGE_EVENTS_ENUM = {
    "INAPP_SHOWN": "inapp_shown",
    "INAPP_DISMISSED": "inapp_dismissed",
    "INLINE_SHOWN": "inline_shown",
    "INLINE_DISMISSED": "inline_dismissed"
};

class MessageContextModule extends React.Component {
    render() {
        const {
            condition: {
                details: {name},
                details,
                count_config
            },
            handleUpdateCondition,
            disabled
        } = this.props;
        return (
            <>
                <div>
                    <EventAttributeQuery
                        {...this.props}
                        {...details}
                        filteredEvents={["inline_shown", "inapp_shown", "inline_dismissed", "inapp_dismissed"]}
                        disabled={disabled}
                        handleUpdate={details => handleUpdateCondition({
                            details
                        })}
                    />
                    {
                        name
                        && <Grid container spacing={16}>
                            <Grid item xs={3}/>
                            <Grid item>
                                <CountConfigModule
                                    countConfig={{...count_config}}
                                    handleUpdateCondition={handleUpdateCondition}
                                />
                            </Grid>
                        </Grid>
                    }
                </div>
            </>
        );
    }
}

class ActivityContextModule extends React.Component {
    render() {
        const {condition: {activity, count_config}, handleUpdateCondition} = this.props;
        const {appState: {appScreenNames = []}} = this.props;
        const {messages2: {new_message: {meta: {platform}}}} = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs={6} md={6}>
                    <MultiSelect
                        options={appScreenNames.map(o => ({label: o, value: o}))}
                        label={"Select Screen"}
                        value={activity}
                        single
                        handleChange={(activity) => handleUpdateCondition({
                            activity, details: {
                                additional_info: {},
                                name: activity
                            }
                        })}
                        style={{marginRight: 8}}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    {activity &&
                    <CountConfigModule countConfig={{...count_config}} handleUpdateCondition={handleUpdateCondition}/>}
                </Grid>
            </Grid>
        );
    }
}

const StyledOperatorContainer = styled.div`
    display: inline-block;
    padding: 4px;
    background-color: #dcdcdc;
    border-radius: 36px;
    border: 2px dashed darkgray;
    margin: 16px 0;
    &::before {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: 30px;
        left: 50%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
    &::after {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: -30px;
        left: -49%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
`;

const COMBINE_OPERATORS_ENUM = {
    OR: {
        label: "OR",
        value: "OR",
        sequence_applicable: false
    },
    AND: {
        label: "AND",
        value: "AND",
        sequence_applicable: false
    },
    AND_THEN: {
        label: "AND THEN",
        value: "AND",
        sequence_applicable: true
    }
}

class CombineOperatorComponent extends React.Component {

    handleOperatorClick = (e, selectedOperator, sequence_enabled = false) => {
        const {updateOperator, operator} = this.props;
        updateOperator(selectedOperator || operator, sequence_enabled);
    };

    render() {
        const {operator, sequenceEnabled} = this.props;
        return (
            <div style={{display: 'flex', justifyContent: "center"}}>
                <StyledOperatorContainer>
                    {
                        Object.keys(COMBINE_OPERATORS_ENUM).map(operatorE => {
                            let isDisabled, isSelected;
                            let currentOperatorData = COMBINE_OPERATORS_ENUM[operatorE];

                            if (sequenceEnabled && currentOperatorData.label === "AND THEN") {
                                isSelected = true;
                                isDisabled = true;
                            } else {
                                if (!sequenceEnabled) {
                                    isSelected = operator === currentOperatorData.label;
                                }
                                isDisabled = operator !== currentOperatorData.label;
                            }

                            return (
                                <ButtonBase
                                    key={"operator_btn_" + operatorE}
                                    style={{
                                        borderRadius: 36,
                                        margin: "0 6px",
                                    }}
                                    disableRipple disableTouchRipple
                                    disabled={isSelected}
                                    onClick={
                                        (e) => {
                                            console.log(currentOperatorData, sequenceEnabled);
                                            this.handleOperatorClick(
                                                e,
                                                COMBINE_OPERATORS_ENUM[operatorE].value,
                                                COMBINE_OPERATORS_ENUM[operatorE].sequence_applicable
                                            )
                                        }
                                    }
                                >
                                    <ClassicCard
                                        style={{minWidth: 100, borderRadius: 36, padding: "8px 4px"}}
                                        selected={isSelected}
                                        disabled={isDisabled}
                                    >
                                        {COMBINE_OPERATORS_ENUM[operatorE].label}
                                    </ClassicCard>
                                </ButtonBase>
                            )
                        })
                    }
                </StyledOperatorContainer>
            </div>
        );
    }
}

function getTermForHeading(ctx_type) {
    switch (ctx_type) {
        case "MESSAGE":
            return "Campaign Event";
        case "APP":
            return "App Event";
        case "ACTIVITY":
            return "Screen";
        case "CLIENT":
            return "Client Event";
        default:
            return "Event";
    }
}

class ContextEventSelectorHeader extends React.Component {
    render() {
        const {
            index,
            condition: {
                context_rule_type
            },
            removeCondition,
            moveCondition,
        } = this.props;
        const {messages2: {new_message: {meta: {platform}, conditions = []}}} = this.props;
        return (
            <Grid container justify={"space-between"}>
                <Grid item xs>
                    <FieldDescription
                        title={getTermForHeading(context_rule_type)}/>
                </Grid>
                <Grid item>
                    {
                        index !== 0
                        && <IconButton onClick={() => moveCondition(index, index - 1)}>
                            <ArrowUpwardIcon fontSize={"small"}/>
                        </IconButton>
                    }
                    {
                        (conditions.length !== 1 && index !== conditions.length - 1)
                        && <IconButton onClick={() => moveCondition(index, index + 1)}>
                            <ArrowDownwardIcon fontSize={"small"}/>
                        </IconButton>
                    }
                    <IconButton style={{color: "red"}} aria-label="Delete Condition" onClick={() => {
                        removeCondition(index)
                    }}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

class ContextEventSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    pickContextModule = (context_rule_type, props) => {
        switch (context_rule_type) {
            case "CLIENT":
                return <ClientContextModule {...props}/>;
            case "APP":
                return <AppEventContextModule {...props}/>;
            case "MESSAGE":
                return <MessageContextModule {...props}/>;
            default:
                return <ActivityContextModule {...props}/>;
        }
    };

    render() {
        const {
            condition: {
                context_rule_type = "ACTIVITY"
            },
            updateOperator,
            combineOperator,
            sequenceEnabled,
        } = this.props;
        return (
            <div>
                <ClassicCard>
                    <ContextEventSelectorHeader {...this.props}/>
                    {this.pickContextModule(context_rule_type, this.props)}
                </ClassicCard>
                <CombineOperatorComponent
                    operator={combineOperator}
                    sequenceEnabled={sequenceEnabled}
                    updateOperator={updateOperator}
                />
            </div>
        );
    }
}

export default ContextEventSelector;