/**
 * Created by Rakesh Peela
 * Date: 20-Mar-2020
 * Time: 11:07 PM
 */

export const GET_APP_SETTINGS = "GET_APP_SETTINGS";
export const GET_APP_SETTINGS_PENDING = "GET_APP_SETTINGS_PENDING";
export const GET_APP_SETTINGS_FAILED = "GET_APP_SETTINGS_FAILED";

export const UPDATE_APP_SETTINGS = "UPDATE_APP_SETTINGS";
export const UPDATE_APP_SETTINGS_PENDING = "UPDATE_APP_SETTINGS_PENDING";
export const UPDATE_APP_SETTINGS_FAILED = "UPDATE_APP_SETTINGS_FAILED";

export const UPDATE_APP_TIMEZONE = "UPDATE_APP_TIMEZONE";
export const UPDATE_APP_TIMEZONE_PENDING = "UPDATE_APP_TIMEZONE_PENDING";
export const UPDATE_APP_TIMEZONE_FAILED = "UPDATE_APP_TIMEZONE_FAILED";

export const RESET_GENERAL_SETTINGS_STATE = "RESET_GENERAL_SETTINGS_STATE";