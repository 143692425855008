/**
 * Created by Uma Sankar Yedida
 * Date: 04-Jan-2020
 * Time: 2:44 PM
 */
import {ACTION_CLASSES} from "./AvailableOptions";
import {BUTTON_CONFIG, DUMMY_CUSTOM_HTML_CONFIG, DUMMY_INAPP_CONFIG, INAPP_LAYOUT_TYPES} from "./common";
import generateShortID from "../../../utils";

const GetDefaultInAppJSON = (type) => {
    const defaultJSON = JSON.parse(JSON.stringify(DUMMY_INAPP_CONFIG));

    let json = {
        ...defaultJSON,
        action_class: ACTION_CLASSES.INAPP,
        layout_type: type,
        _id: generateShortID()
    };

    switch (type) {
        case INAPP_LAYOUT_TYPES.HEADER:
            return {
                ...json,
                window_attributes: {
                    ...json.window_attributes,
                    position: "TOP"
                },
                html_config: {
                    ...json.html_config,
                    height: 15
                }
            };
        case INAPP_LAYOUT_TYPES.FOOTER:
            return {
                ...json,
                window_attributes: {
                    ...json.window_attributes,
                    position: "BOTTOM"
                },
                html_config: {
                    ...json.html_config,
                    height: 15
                }
            };

        case INAPP_LAYOUT_TYPES.CUSTOM_HTML:
            return {
                ...DUMMY_CUSTOM_HTML_CONFIG,
                action_class: ACTION_CLASSES.INAPP,
                layout_type: type,
                orientation: "Portrait"
            };
        case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
            return {
                ...json,
                buttons_config: [
                    {
                        ...BUTTON_CONFIG,
                        "color": "#FA7404",
                    }
                ]
            };
        case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
            return {
                ...json,
                text_config: {},
                buttons_config: [
                    {
                        ...BUTTON_CONFIG,
                        "color": "#FA7404",
                    }
                ]
            };
        case INAPP_LAYOUT_TYPES.CLASSIC_MODAL:
        default:
            return json;
    }
};

export default GetDefaultInAppJSON;