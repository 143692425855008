import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import {API_BASE, API_ROOT, API_ROOT_V4} from "../../../../../../../config";
import {
    downloadJSONAsJSONFile,
    formatTime,
    getPercent,
    minMaxAverageOf,
    randomColorWithIndex,
    roundOffNumber, withQueryStrings
} from "../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import Box from "../../../../../../../components/reusable/Box";
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import CheckBox from '@material-ui/icons/CheckBox';
import People from '@material-ui/icons/People';
import AccessTime from '@material-ui/icons/AccessTime';
import StarRate from '@material-ui/icons/StarRate';
import AttributeDistributions from "../../../../../../../components/reusable/AttributeDistributions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from "react-router/es/Link";

const ANSWER_MODES = {
    multi_choice: 'multi_choice',
    single_choice: 'single_choice',
    rating: 'rating'
};

const CustomButton = ({fullWidth = true, buttonVariant = "outlined", textVariant = "subheading", children}) => (
    <Button disabled fullWidth={fullWidth} variant={buttonVariant}><Typography
        variant={textVariant}>{children}</Typography></Button>
);

export class Stats extends Component {

    render() {

        const {
            surveys: {
                survey: {meta: {name = "Unknown"} = {}} = {},
                results: {start_date, end_date, avg_completion_time, respondents, completion_percent} = {},
                results, others
            }
        } = this.props;

        return (
            <Grid container spacing={16} style={{marginBottom: 16}} justify="flex-end" alignContent="flex-end">
                <Grid item xs={6} md={2}>
                    <CustomButton
                        textvariant="body2">{formatTime(start_date, "Do MMM")} - {formatTime(end_date, "Do MMM")}</CustomButton>
                </Grid>
                <Grid item xs={6} md={4}>
                    <CustomButton>Avg <strong>{roundOffNumber(avg_completion_time / 60)}</strong> Mins for
                        completion</CustomButton>
                </Grid>
                <Grid item xs={6} md={2}>
                    <CustomButton><strong>{respondents} </strong>&nbsp; Respondents</CustomButton>
                </Grid>
                <Grid item xs={6} md={2}>
                    <CustomButton><strong>{Math.round(completion_percent * 100) / 100} % </strong> Completion</CustomButton>
                </Grid>
                <Grid item xs={4} md={2}>
                    <Button
                        fullWidth variant="contained"
                        color="primary"
                        onClick={e => {
                            e.preventDefault();
                            downloadJSONAsJSONFile({results, others}, name);
                        }}
                    >Export &nbsp; <DownloadIcon/></Button>
                </Grid>
            </Grid>
        )
    }

}

const NPSSlab = ({total, value, color, title}) => (
    <div style={{
        display: 'inline-block',
        width: `${getPercent(value, total)}%`,
        backgroundImage: `linear-gradient(45deg, ${color} 50%, transparent 25%, transparent 50%, ${color} 50%, ${color} 75%, transparent 75%, #fff)`,
        backgroundSize: '2px 2px',
        padding: value > 0 ? 8 : 0
    }}>
        {value > 0 &&
        <Fragment>
            <Typography variant="subtitle1" style={{color: "#FFF"}}>{value}</Typography>
            <Typography variant="subtitle1" style={{color: "#FFF"}}>{title}</Typography>
        </Fragment>
        }
    </div>
);

const calculateNPSSlabs = (data = []) => {
    const total = data.reduce((a, b) => a + b.count, 0);
    const strengthOfValues = (values = []) => data.reduce((a, b) => a + (values.includes(b.value) ? b.count : 0), 0);
    return {
        total,
        promoters: strengthOfValues([4, 5]),
        passive: strengthOfValues([3]),
        detractors: strengthOfValues([1, 2])
    }
};

export class NPSQuestion extends Component {

    render() {
        const {responses, title} = this.props;
        const {total, promoters, passive, detractors} = calculateNPSSlabs(responses);
        return (
            <Box title={title} icon={<StarRate/>}>
                <Grid container spacing={16}>
                    <Grid item xs={12} style={{margin: 8}}>
                        <NPSSlab value={promoters} color={randomColorWithIndex(0)} title="Promoters" total={total}/>
                        <NPSSlab value={passive} color={randomColorWithIndex(3)} title="Passive" total={total}/>
                        <NPSSlab value={detractors} color={randomColorWithIndex(6)} title="Detractors" total={total}/>
                    </Grid>
                </Grid>
                <MultiUtilityChart
                    xType="number"
                    yType="category"
                    xLabelFormatter={l => l}
                    layout="vertical"
                    withoutAggregations
                    height={300}
                    dataKey="count"
                    yLabel={"Choice"}
                    xLabel="Responses"
                    data={responses}
                    barDataKeys={["count"]}
                    fullWidth
                    yDataKey="value"
                />
            </Box>
        )
    }

}

class OtherOptionResults extends Component {
    state = {
        downloadInProgress: false,
    }

    render() {
        const {
            params: {appId, surveyId},
            questionId, id, value,
            surveyResultForOtherOption,
            surveys: {others = {}}
        } = this.props;

        const {
            session: {user: {email}},
            filters,
        } = this.props;

        const {downloadInProgress} = this.state;

        const data = others[questionId + '-' + id];
        return (
            <Grid container>
                <Grid item xs={12}>
                    {!data && <Button
                        variant="text"
                        onClick={e => {
                            surveyResultForOtherOption(appId, surveyId, questionId, id);
                        }}
                    ><strong>{value} </strong> &nbsp;responses</Button>}

                    {data &&
                    <div style={{margin: 16}}>
                        <Typography variant="body2">{data.choice}</Typography>
                        <List dense style={{maxHeight: 320, overflowY: 'auto'}}>
                            {data.responses.map(({user_id, response}, index) => (
                                <Link title="Click to go to User" key={user_id + response + index} target="_blank"
                                      to={`/apps/${appId}/user/${user_id}`}>
                                    <ListItem button disableGutters>
                                        <ListItemText primary={response}/>
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                    }

                    {data && <Tooltip id="tooltip-fab-download" title="Download Responses as CSV" placement="bottom">
                        <Button size={"small"} disabled={downloadInProgress} style={{margin: '0 10px'}}
                                variant={"contained"}
                                color="primary" onClick={() => {
                            this.setState({
                                downloadInProgress: true
                            });
                            const isv4App = [].includes(appId);
                            axios.request({
                                url: `${isv4App ? API_BASE + API_ROOT_V4 : API_BASE + API_ROOT}surveys/${surveyId}/results/others/download?customerId=${email}&appId=${appId}` +
                                    withQueryStrings({...filters, questionId, answerId: id}),
                                method: "POST",
                                responseType: "blob",
                                data: {"user": [], "session": [], "event": []}
                            })
                                .then(response => {
                                    let blob = new Blob(
                                        [response.data],
                                        {type: response.headers["content-type"]}
                                    );
                                    let link = document.createElement("a");
                                    link.href = window.URL.createObjectURL(blob);
                                    link.download = "other_responses.csv";
                                    link.click();
                                    link.remove();
                                    this.setState({downloadInProgress: false});
                                })
                                .catch(error => {
                                    if (error) {
                                        this.setState({
                                            downloadInProgress: false
                                        });
                                    }
                                });
                        }}>
                            {!downloadInProgress && <DownloadIcon style={{color: "#ffffff"}}/>}
                            {downloadInProgress && <CircularProgress size={24} style={{color: "white"}}/>}
                        </Button>
                    </Tooltip>
                    }
                </Grid>
            </Grid>
        )
    }
}

export class ChoiceQuestion extends Component {

    render() {
        const {
            id, title, responses = [], number, answer_mode, choices = [], others,
        } = this.props;
        const otherOptionChoiceIndex = choices.findIndex(o => o.is_other);
        const options = responses.map(item => item.value);
        const modifiedResponses = responses.map((x, index) => ({...x, value: `Option-${index + 1}`}))
        return (
            <Box
                icon={answer_mode === ANSWER_MODES.single_choice ? <RadioButtonChecked/> : <CheckBox/>}
                title={title}
                footer={
                    otherOptionChoiceIndex > -1 && <OtherOptionResults
                        {...this.props}
                        {...choices[otherOptionChoiceIndex]}
                        questionId={id}
                    />
                }
            >
                <Grid container>
                    <Grid item xs={9} spacing={32}>
                        <MultiUtilityChart
                            xType="number"
                            yType="category"
                            xLabelFormatter={l => l}
                            layout="vertical"
                            withoutAggregations
                            height={300}
                            dataKey="count"
                            yLabel={"Choice"}
                            xLabel="Responses"
                            data={modifiedResponses}
                            barDataKeys={["count"]}
                            fullWidth
                            yDataKey="value"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant={"h6"}>
                            Legend
                        </Typography>
                        <List style={{
                            maxHeight: 300,
                            overflowY: "scroll",
                            borderRadius: 4,
                            border: "1px solid #bbc9d5",
                            padding: 0
                        }}>
                            {
                                options.map((option, index) => <ListItem>
                                    <ListItemText
                                        primary={option} secondary={`Option ${index + 1}`}
                                        style={{display: "flex", flexDirection: "column-reverse"}}
                                    />
                                </ListItem>)
                            }
                        </List>
                    </Grid>
                </Grid>
            </Box>
        )
    }

}

export class ResultsHolder extends Component {

    render() {
        const {
            surveys: {results: {data = []} = {}, result_distributions: {user, session} = {}},
        } = this.props;
        return (
            <Fragment>
                <Stats surveys={this.props.surveys}/>
                {/*{
                            user && <AttributeDistributions
                            title="User Properties"
                            icon={<People/>}
                            boxCollapsible
                            data={user}
                            notSelectable
                            withoutAttributeSelect
                            {...this.props}
                            />
                            }
                            {
                            session && <AttributeDistributions
                            title="Session Properties"
                            icon={<AccessTime/>}
                            boxCollapsible
                            data={session}
                            notSelectable
                            withoutAttributeSelect
                            {...this.props}
                            />
                            }*/}
                <Typography variant="h4" style={{marginBottom: 16}}>Responses</Typography>
                {
                    data.map(item =>
                        item.answer_mode === ANSWER_MODES.rating ? <NPSQuestion key={item.id} {...item}/> :
                            <ChoiceQuestion
                                {...this.props}
                                key={item.id}
                                {...item}
                            />
                    )
                }
            </Fragment>
        )
    }

}