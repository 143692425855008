/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 1:41 PM
 */

import {Button, Grid, Typography, withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import debounce from "lodash/debounce";
import React from 'react';
import HTMLEditor from "../../../../../../../../../../components/plugins/HTMLEditor";
import {capitalizeEachWord} from "../../../../../../../../../../utils";
import generateShortID from "../../../utils";
import {addNewStepToCampaign} from "../actions";
import ActionsTextField from "../components/ActionsTextField";
import ActionTypeSelector from "../components/ActionTypeSelector";
import CampaignStepCard from "../components/CampaignStepCard";
import DesignPreview from "../components/DesignPreview";
import DummyInsetContainer from "../components/DummyInsetContainer";
import ExpansionModuleConfig from "../components/ExpansionModuleConfig";
import ModuleDivider from "../components/ModuleDivider";
import ModulesConfigurator from "../components/modules";
import CustomColorPickerModule from "../components/modules/CustomColorPickerModule";
import CustomNativeSelect from "../components/modules/CustomNativeSelect";
import ModuleConfigSection from "../components/modules/ModuleConfigSection";
import TargetConfiguration from "../components/modules/TargetConfiguration";
import MessageSimulator from "../components/Simulator";
import {ACTION_CLASSES} from "../models/AvailableOptions";
import {
    ACTION_BUTTON_CONFIG,
    BUTTON_CONFIG,
    INAPP_LAYOUT_TYPES,
    MESSAGE_TOOLTIP_LAYOUT_TYPES,
    TEXT_CONFIG
} from "../models/common";
import {ButtonGroup, constructHTML, CustomActionField, DEFAULT_IMAGE_SIZE_TYPES} from "../utils";

const page2_styles = () => ({});

const range = (start, stop, step) => Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));

const BORDER_RADIUS_ENUM = [2, 4, 8, 16].map(x => ({label: x, value: x}));
const COACHMARK_SHAPE_TYPES = [{label: "circle", value: "circle"}, {label: "rectangle", value: "rectangle"}];
const MAX_HEIGHT_ENUM = range(20, 100, 1).map(x => ({label: x, value: x}));
const BORDER_WIDTH_ENUM = [2, 4, 6, 8].map(x => ({label: x, value: x}));
const BUTTONS_COUNT_ENUM = [0, 1, 2, 3].map(x => ({label: x > 0 ? x : "None", value: x}));
const MESSAGE_POSITION_ENUM = ["Top", "Right", "Bottom", "Left", "Center"].map(x => ({label: x, value: x}));
const MESSAGE_TOOLTIP_TEXT_TYPE_ENUM = [
    {label: "Description Text Only", key: 0},
    {label: "Title & Description", key: 1},
];
const NUDGE_POSITION_ENUM = ["left", "top_center", "right", "center"].map(x => ({label: x, value: x.toLowerCase()}));
const NUDGE_ANIMATION_ENUM = ["none", "cycle"].map(x => ({label: x, value: x.toLowerCase()}));

const isCustomHTMLTemplate = (action_class, layout_type) => action_class === ACTION_CLASSES.INAPP && layout_type === INAPP_LAYOUT_TYPES.CUSTOM_HTML;

class FlowBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingDialog: false,
            nextStepData: null
        }
    }

    componentWillUnmount() {
        this.setState({
            nextStepData: null
        })
    }

    handleEditStepSelect = (stepIndex) => {
        const {uis, updateOnSelectStep, updateCurrentStepInCampaign, nullifySelectedModule} = this.props;
        updateCurrentStepInCampaign(stepIndex);
        updateOnSelectStep(uis[stepIndex], stepIndex);
        nullifySelectedModule();
    };

    handleNewStepSelect = (stepIndex) => {
        this.setState({
            showingDialog: true
        })
    };

    handleDeleteStep = (stepIndex) => {
        const {deleteStepFromCampaign, nullifySelectedModule, updateCurrentStepInCampaign} = this.props;
        deleteStepFromCampaign(stepIndex);
        nullifySelectedModule();
        updateCurrentStepInCampaign(null);
    };

    handleCloseDialog = () => {
        this.setState({
            showingDialog: false,
            nextStepData: null
        })
    };

    handleDialogCancel = () => {
        this.setState({
            showingDialog: false,
            nextStepData: null
        })
    };

    handleDialogOk = () => {
        const {
            bindedDispatch, updateCurrentStepInCampaign, nullifySelectedModule,
            messages2: {new_message: {uis}}
        } = this.props;
        const {nextStepData} = this.state;
        updateCurrentStepInCampaign(uis.length);
        nullifySelectedModule();
        bindedDispatch(addNewStepToCampaign(nextStepData));
        this.setState({
            showingDialog: false,
            nextStepData: null
        })
    };

    updateNewStepData = (data) => {
        this.setState({
            nextStepData: data
        })
    };

    render() {
        const {uis, currentStep, updateInNewUI, showDialog} = this.props;
        const {showingDialog} = this.state;
        const isDevEnv = process.env.NODE_ENV !== "production";
        return (
            <Grid container style={{
                minHeight: 160,
                backgroundColor: "#f2f2f2"
            }}>
                <Grid item xs={12} style={{
                    overflowX: "overlay",
                    overflowY: "hidden",
                    padding: 24,
                    boxShadow: "inset 0 -5px 10px -10px rgba(0,0,0,0.75)",
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 90 90'%3E%3Ccircle fill='%23f0f0f0' cx='45' cy='45' r='12'/%3E%3Cg fill='%23e6e6e6' fill-opacity='1'%3E%3Ccircle cx='0' cy='90' r='10'/%3E%3Ccircle cx='90' cy='90' r='10'/%3E%3Ccircle cx='90' cy='0' r='10'/%3E%3Ccircle cx='0' cy='0' r='10'/%3E%3C/g%3E%3C/svg%3E")`
                }}>
                    <div style={{display: "flex"}}>
                        {
                            Array.isArray(uis) && uis.map((item, index) => (
                                    <CampaignStepCard
                                        currentStep={currentStep}
                                        updateInNewUI={updateInNewUI}
                                        selected={index === currentStep}
                                        key={"key_card_" + item._id}
                                        itemIndex={index}
                                        ui={item}
                                        deleteHandler={this.handleDeleteStep}
                                        handler={this.handleEditStepSelect}
                                        showDialog={() => showDialog()}
                                    />
                                )
                            )
                        }
                        <CampaignStepCard handler={this.handleNewStepSelect} createNew/>
                    </div>
                </Grid>
                <Dialog
                    maxWidth={isDevEnv ? "lg" : "sm"}
                    fullWidth
                    open={showingDialog}
                    onClose={this.handleCloseDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    <DialogTitle>Choose Template</DialogTitle>
                    <DialogContent>
                        <ActionTypeSelector messages={this.props.messages2} updateNewStepData={this.updateNewStepData}
                                            successCallback={this.handleDialogOk}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDialogOk} color="primary"
                                disabled={this.state.nextStepData === null}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    }
}

class Page2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModule: null,
            selectedConfig: null,
            currentStep: null,
            showPreviewDialog: false,
            wv_tag: this.props.messages2.new_ui.wv_tag || ""
        };

        this.debounce = (delay) => debounce((fn, data, stepIndex) => {
            fn(data, stepIndex)
        }, delay);
    }

    nullifySelectedModule = () => {
        this.setState({
            selectedModule: null,
            selectedConfig: null
        })
    };

    handleModuleSelection = (module, config) => {
        const selectedModule = this.state.selectedModule;
        this.setState({
            selectedModule: null,
            selectedConfig: config,
        }, () => {
            this.setState({
                selectedModule: selectedModule === module ? null : module,
            })
        })
    };

    updateCurrentStepInCampaign = (stepIndex) => {
        this.setState({
            currentStep: stepIndex
        })
    };

    getTooltipTextType = (ui) => {
        if (ui.hasOwnProperty("title_config") && ui.hasOwnProperty("text_config")) {
            return 1;
        } else if (ui.hasOwnProperty("text_config") && !(ui.hasOwnProperty("title_config"))) {
            return 0;
        } else {
            return 0;
        }
    };

    isValidUI = (ui_config, action_class) => {
        const classKeysMap = {
            [ACTION_CLASSES.TOOLTIP]: ["name", "activity", "delay", "display_type", "type", "layout_type", "text_config", "buttons_config", "bg_color", "opacity", "position", "border", "button_position", "corners", "dimmed_background", "dismiss_outside_touch", "view_id", "animation", "language_attribute", "localization_enabled"],
            [ACTION_CLASSES.NUDGE]: ["name", "view_id", "activity", "bg_color", "layout_type", "has_ripple", "display_type", "type"],
            [ACTION_CLASSES.INAPP]: ["layout", "type", "window_attributes", "html_config"],
            [ACTION_CLASSES.COACHMARK]: ["name", "view_id", "activity", "bg_color", "layout_type", "coach_mark_details", "display_type", "type", "coach_mark_details", "text_config"],
        };
        return classKeysMap[action_class] && classKeysMap[action_class].every(key => {
            return Object.keys(ui_config).includes(key)
        })
    };

    /**
     * Handles updates for InApp container configurations
     *
     * @param e
     * @param updateInNewUIWrapper
     * @param key
     */
    handleOnUpdate = (e, updateInNewUIWrapper, key) => {
        const {messages2: {new_ui}} = this.props;
        const {currentStep} = this.state;

        let object = {};
        switch (key) {
            case 'dimmed_background':
                object = {
                    window_attributes: {
                        ...new_ui.window_attributes,
                        [key]: e.target.checked,
                    }
                };
                break;
            case 'dismiss_outside_touch':
                object = {
                    window_attributes: {
                        ...new_ui.window_attributes,
                        [key]: e.target.checked,
                    }
                };
                break;
            case 'height':
            case 'data':
                object = {
                    html_config: {
                        ...new_ui.html_config,
                        url: "",
                        [key]: e,
                    }
                };
                break;
            case 'url':
                object = {
                    html_config: {
                        ...new_ui.html_config,
                        data: "",
                        [key]: e,
                    }
                };
                break;
            case 'only_image':
            case 'image_size':
            case 'bg_image':
            case 'border_radius':
            case 'touch_redirect_url':
                object = {
                    [key]: e,
                };
                break;
        }

        const newUI = {
            ...new_ui,
            ...object
        };

        updateInNewUIWrapper(newUI, currentStep);
    };

    render() {
        const {hidden, updateInNewUI, appState: {appScreenNames}} = this.props;
        const {selectedModule, selectedConfig, currentStep, wv_tag} = this.state;
        const {
            messages2: {new_ui, new_message: {uis, meta: {platform}}, new_ui: {is_wv = false}},
            updateOnSelectStep, deleteStepFromCampaign
        } = this.props;
        const {delay, layout_type, action_class, activity, view_id, dismiss_outside_touch, dim_background = false} = new_ui;
        const isActionButtonsLayout = layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS;
        let btns_mod_key = isActionButtonsLayout ? "action_buttons_config" : "buttons_config";
        let withDimBg = [MESSAGE_TOOLTIP_LAYOUT_TYPES.DEFAULT].includes(layout_type);

        /* A wrapper function for `updateInNewUI` */
        const updateInNewUIWrapper = (...args) => {

            if (action_class === ACTION_CLASSES.INAPP) {
                // For InApp only whenever a change happens in Front end components, construct the HTML and store it
                args[0] = constructHTML(args[0]);
            }

            updateInNewUI(...args);
        };

        return (
            <div>
                {!hidden &&
                <div>
                    <FlowBuilder
                        currentStep={currentStep} uis={uis}
                        nullifySelectedModule={this.nullifySelectedModule}
                        deleteStepFromCampaign={deleteStepFromCampaign}
                        updateCurrentStepInCampaign={this.updateCurrentStepInCampaign}
                        updateOnSelectStep={updateOnSelectStep}
                        {...this.props}
                        showDialog={() => this.setState({
                            showPreviewDialog: true
                        })}
                    />
                    {
                        /* Show Dialog when `showPreviewDialog` flag is true. Otherwise Hide Dialog */
                        this.state.showPreviewDialog
                        && <DesignPreview
                            appPlatform={platform}
                            updateInNewUI={updateInNewUI}
                            currentStep={currentStep}
                            showPreviewDialog={this.state.showPreviewDialog}
                            ui={new_ui}
                            handleCloseDialog={() => this.setState({
                                showPreviewDialog: false,
                            })}
                            handleDialogCancel={() => this.setState({
                                showPreviewDialog: false,
                            })}
                        />
                    }
                    {new_ui && new_ui !== {} && action_class && this.isValidUI(new_ui, action_class) && (
                        <Grid container justify={"center"} alignItems={"stretch"} style={{height: 640}}>
                            <Grid item xs={isCustomHTMLTemplate(action_class, layout_type) ? 6 : 7}>
                                <div style={{
                                    background: "#ebebed",
                                    height: "100%",
                                    display: "flex",
                                    alignSelf: "center"
                                }}>
                                    <MessageSimulator
                                        selectedModule={selectedModule}
                                        handleModuleSelection={this.handleModuleSelection}
                                        updateInNewUI={updateInNewUIWrapper}
                                        currentStep={currentStep}
                                        {...this.props}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={isCustomHTMLTemplate(action_class, layout_type) ? 6 : 5}>
                                <div style={{
                                    height: "100%",
                                    backgroundColor: "#f7f7fa",
                                    maxHeight: 640,
                                    overflowY: "overlay",
                                    overflowX: "hidden"
                                }}>
                                    <DummyInsetContainer
                                        style={{
                                            borderRadius: 0,
                                            padding: 10,
                                            paddingRight: 16,
                                            backgroundColor: "#f7f7fa",
                                            marginBottom: 0
                                        }}>
                                        <Grid container direction="column" alignItems="stretch" spacing={16}>
                                            <Grid item>
                                                {
                                                    action_class !== ACTION_CLASSES.INAPP
                                                    && <ExpansionModuleConfig title={"Target Configuration"}
                                                                              defaultExpanded>
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color={"primary"}
                                                                        checked={is_wv}
                                                                        onChange={(e) => {
                                                                            let url = "https://unpkg.com/apxor-rtm-ui";
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                is_wv: e.target.checked,
                                                                                url: e.target.checked ? url : undefined
                                                                            })
                                                                        }}
                                                                    />
                                                                }
                                                                label="For WebView"
                                                            />
                                                            {
                                                                is_wv && <ModuleConfigSection title={"WebView Tag"}>
                                                                    <ActionsTextField
                                                                        style={{maxWidth: 360}}
                                                                        value={wv_tag}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            this.setState({
                                                                                wv_tag: value
                                                                            }, () => {
                                                                                this.debounce(300)(updateInNewUIWrapper, {
                                                                                    ...new_ui,
                                                                                    wv_tag: value
                                                                                }, currentStep);
                                                                            })
                                                                        }}
                                                                    />
                                                                </ModuleConfigSection>
                                                            }
                                                        </div>
                                                        <TargetConfiguration
                                                            key={layout_type + "_target_"}
                                                            appScreenNames={appScreenNames}
                                                            platform={platform}
                                                            activity={activity}
                                                            view_id={view_id}
                                                            showDialog={() => {
                                                                this.setState({
                                                                    showPreviewDialog: true
                                                                })
                                                            }}
                                                            handleOnValueChange={
                                                                (data, key) => this.debounce(300)(updateInNewUI, {
                                                                    ...new_ui,
                                                                    [key]: data
                                                                }, currentStep)
                                                            }
                                                            {...this.props}
                                                        />
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            marginTop: 12
                                                        }}>
                                                            <Typography>Delay by </Typography>
                                                            <ActionsTextField
                                                                value={delay / 1000}
                                                                style={{maxWidth: 72, marginBottom: 0, margin: "0 6px"}}
                                                                InputProps={{min: 1}}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    this.setState({
                                                                        currentStepDelay: Number(value) * 1000
                                                                    }, () => {
                                                                        this.debounce(100)(updateInNewUIWrapper, {
                                                                            ...new_ui,
                                                                            delay: Number(value) * 1000
                                                                        }, currentStep);
                                                                    })
                                                                }}
                                                                type={"number"}
                                                            />
                                                            <Typography> {delay > 1000 ? "seconds" : "second"} </Typography>
                                                        </div>
                                                    </ExpansionModuleConfig>
                                                }
                                                {
                                                    action_class === ACTION_CLASSES.COACHMARK
                                                    && <ExpansionModuleConfig title={"Coachmark Configuration"}
                                                                              defaultExpanded>
                                                        <Grid container>
                                                            <Grid item xs={3} md={3}>
                                                                <ModuleConfigSection title={"Background Color"}>
                                                                    <CustomColorPickerModule
                                                                        color={new_ui.bg_color}
                                                                        handleColorChange={(color) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                bg_color: color.hex
                                                                            }, currentStep)
                                                                        }}/>
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                            <Grid item xs={3} md={3}>
                                                                <ModuleConfigSection title={"Shape"}>
                                                                    <CustomNativeSelect
                                                                        options={COACHMARK_SHAPE_TYPES}
                                                                        defaultValue={new_ui.coach_mark_details.type}
                                                                        itemName={"coachmark_shape_types"}
                                                                        handleChange={(event) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                coach_mark_details: {
                                                                                    ...new_ui.coach_mark_details,
                                                                                    type: event.target.value
                                                                                }
                                                                            }, currentStep)
                                                                        }}
                                                                    />
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                            <Grid item xs={3} md={3}>
                                                                <ModuleConfigSection title={"Position"}>
                                                                    <CustomNativeSelect
                                                                        options={
                                                                            MESSAGE_POSITION_ENUM
                                                                            .filter(x => x.label !== "Center")
                                                                            .map(pos => ({
                                                                                    label: pos.label.toUpperCase(),
                                                                                    value: pos.value.toUpperCase()
                                                                                })
                                                                            )
                                                                        }
                                                                        defaultValue={new_ui.position}
                                                                        itemName={"coachmark_position"}
                                                                        handleChange={(event) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                position: event.target.value
                                                                            }, currentStep)
                                                                        }}
                                                                    />
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <CustomActionField
                                                                    key={"opacity_coachmark"}
                                                                    type={"number"}
                                                                    title={"Opacity"}
                                                                    InputProps={{
                                                                        min: 0,
                                                                        max: 1,
                                                                        step: "0.1",
                                                                        type: "number"
                                                                    }}
                                                                    placeholder={"0.6"}
                                                                    value={new_ui.opacity}
                                                                    onActionFieldValueChange={
                                                                        (value) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                opacity: Number(value) || 0,
                                                                            }, currentStep)
                                                                        }
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <FormControlLabel
                                                                    label={"Ripple"}
                                                                    control={
                                                                        <Checkbox
                                                                            color={"primary"}
                                                                            checked={new_ui.coach_mark_details.has_ripple}
                                                                            onChange={(e) => {
                                                                                updateInNewUIWrapper({
                                                                                    ...new_ui,
                                                                                    coach_mark_details: {
                                                                                        ...new_ui.coach_mark_details,
                                                                                        has_ripple: e.target.checked
                                                                                    }
                                                                                }, currentStep)
                                                                            }}
                                                                            disableRipple
                                                                        />
                                                                    }
                                                                />
                                                                <FormControlLabel
                                                                    label={"Arrow"}
                                                                    control={
                                                                        <Checkbox
                                                                            color={"primary"}
                                                                            checked={new_ui.coach_mark_details.has_arrow}
                                                                            onChange={(e) => {
                                                                                updateInNewUIWrapper({
                                                                                    ...new_ui,
                                                                                    coach_mark_details: {
                                                                                        ...new_ui.coach_mark_details,
                                                                                        has_arrow: e.target.checked
                                                                                    }
                                                                                }, currentStep)
                                                                            }}
                                                                            disableRipple
                                                                        />
                                                                    }
                                                                />
                                                                <FormControlLabel
                                                                    label={"Dismiss on Touch Outside"}
                                                                    control={
                                                                        <Checkbox
                                                                            color={"primary"}
                                                                            checked={dismiss_outside_touch}
                                                                            onChange={(e) => {
                                                                                updateInNewUIWrapper({
                                                                                    ...new_ui,
                                                                                    dismiss_outside_touch: e.target.checked,
                                                                                }, currentStep)
                                                                            }}
                                                                            disableRipple
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionModuleConfig>
                                                }
                                                {action_class === ACTION_CLASSES.TOOLTIP &&
                                                <ExpansionModuleConfig
                                                    title={"Container Configuration"}
                                                    defaultExpanded
                                                >
                                                    <Grid container>
                                                        <Grid item xs={4} md={4}>
                                                            <ModuleConfigSection title={"Background Color"}>
                                                                <CustomColorPickerModule
                                                                    color={new_ui.bg_color}
                                                                    handleColorChange={(color) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            bg_color: color.hex
                                                                        }, currentStep)
                                                                    }}/>
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                        <Grid item xs={4} md={4}>
                                                            <ModuleConfigSection title={"Border Radius"}>
                                                                <CustomNativeSelect
                                                                    options={BORDER_RADIUS_ENUM}
                                                                    defaultValue={new_ui.corners.width}
                                                                    itemName={"style"}
                                                                    handleChange={(event) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            corners: {
                                                                                ...new_ui.corners,
                                                                                width: Number(event.target.value)
                                                                            }
                                                                        }, currentStep)
                                                                    }}
                                                                />
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                        <Grid item xs={4} md={4}>
                                                            <ModuleConfigSection title={"Tooltip Position"}>
                                                                <CustomNativeSelect
                                                                    options={
                                                                        MESSAGE_POSITION_ENUM
                                                                        .filter(x => x.label !== "Center")
                                                                        .map(x => ({
                                                                            label: x.label.toUpperCase(),
                                                                            value: x.value.toUpperCase()
                                                                        }))
                                                                    }
                                                                    defaultValue={new_ui.position.toUpperCase()}
                                                                    itemName={"message_position"}
                                                                    handleChange={(event) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            position: (event.target.value).toUpperCase()
                                                                        }, currentStep)
                                                                    }}
                                                                />
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                    </Grid>
                                                    {withDimBg && <ModuleDivider/>}
                                                    {
                                                        withDimBg
                                                        && <Grid container>
                                                            <Grid item xs={4}>
                                                                <FormControlLabel
                                                                    label={"Dim Background"}
                                                                    control={
                                                                        <Checkbox
                                                                            color={"primary"}
                                                                            checked={dim_background}
                                                                            onChange={(e) => {
                                                                                updateInNewUIWrapper({
                                                                                    ...new_ui,
                                                                                    dim_background: e.target.checked,
                                                                                    buttons_config: [],
                                                                                    animation: {
                                                                                        ...new_ui,
                                                                                        enabled: false
                                                                                    }
                                                                                }, currentStep)
                                                                            }}
                                                                            disableRipple
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                            {
                                                                dim_background
                                                                && <>
                                                                    <Grid item xs={4} md={4}>
                                                                        <ModuleConfigSection
                                                                            title={"Dim Background Color"}>
                                                                            <CustomColorPickerModule
                                                                                color={new_ui.dim_background_color}
                                                                                handleColorChange={(color) => {
                                                                                    updateInNewUIWrapper({
                                                                                        ...new_ui,
                                                                                        dim_background_color: color.hex
                                                                                    }, currentStep)
                                                                                }}/>
                                                                        </ModuleConfigSection>
                                                                    </Grid>

                                                                    <Grid item xs={2} md={2}>
                                                                        <CustomActionField
                                                                            key={"opacity_dim_background"}
                                                                            type={"number"}
                                                                            title={"Dim Opacity"}
                                                                            InputProps={{
                                                                                min: 0,
                                                                                max: 1,
                                                                                step: "0.1",
                                                                                type: "number"
                                                                            }}
                                                                            placeholder={"0.6"}
                                                                            value={new_ui.dim_background_opacity}
                                                                            onActionFieldValueChange={
                                                                                (value) => {
                                                                                    updateInNewUIWrapper({
                                                                                        ...new_ui,
                                                                                        dim_background_opacity: Number(value) || 0
                                                                                    }, currentStep)
                                                                                }
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </Grid>
                                                    }
                                                    {!isActionButtonsLayout && <ModuleDivider/>}
                                                    <Grid container>
                                                        {layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT &&
                                                        <Grid item xs={3} md={3}>
                                                            <ModuleConfigSection title={"Border Color"}>
                                                                <CustomColorPickerModule
                                                                    color={new_ui.border.color}
                                                                    handleColorChange={(color) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            border: {
                                                                                ...new_ui.border,
                                                                                color: color.hex
                                                                            }
                                                                        }, currentStep)
                                                                    }}/>
                                                            </ModuleConfigSection>
                                                        </Grid>}
                                                        {layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT &&
                                                        <Grid item xs={3} md={3}>
                                                            <ModuleConfigSection title={"Border Width"}>
                                                                <CustomNativeSelect
                                                                    options={BORDER_WIDTH_ENUM}
                                                                    defaultValue={new_ui.border.width}
                                                                    itemName={"style"}
                                                                    handleChange={(event) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            border: {
                                                                                ...new_ui.border,
                                                                                width: Number(event.target.value)
                                                                            }
                                                                        }, currentStep)
                                                                    }}
                                                                />
                                                            </ModuleConfigSection>
                                                        </Grid>}
                                                        {
                                                            (![
                                                                MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
                                                            ].includes(layout_type))
                                                            && <Grid item xs={4} md={4}>
                                                                <ModuleConfigSection title={"Tooltip Text Type"}>
                                                                    <CustomNativeSelect
                                                                        options={MESSAGE_TOOLTIP_TEXT_TYPE_ENUM}
                                                                        defaultValue={this.getTooltipTextType(new_ui)}
                                                                        itemName={"message_tooltip_text"}
                                                                        handleChange={(event) => {
                                                                            const value = Number(event.target.value);
                                                                            let {text_config, title_config, ...other_keys} = new_ui;
                                                                            this.nullifySelectedModule();
                                                                            if (value === 0) {
                                                                                updateInNewUIWrapper({
                                                                                    ...other_keys,
                                                                                    text_config: {
                                                                                        ...TEXT_CONFIG,
                                                                                        size: 16,
                                                                                        __id: generateShortID()
                                                                                    }
                                                                                }, currentStep)
                                                                            } else if (value === 1) {
                                                                                updateInNewUIWrapper({
                                                                                    ...other_keys,
                                                                                    text_config: {
                                                                                        ...TEXT_CONFIG,
                                                                                        __id: generateShortID()
                                                                                    },
                                                                                    title_config: {
                                                                                        ...TEXT_CONFIG,
                                                                                        size: 18,
                                                                                        style: "bold",
                                                                                        __id: generateShortID()
                                                                                    }
                                                                                }, currentStep)
                                                                            }
                                                                        }}
                                                                    />
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                        }
                                                        {
                                                            (![
                                                                MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT,
                                                            ].includes(layout_type)) && !dim_background &&
                                                            <Grid item xs={4} md={4}>
                                                                <ModuleConfigSection title={"Number of Buttons"}>
                                                                    <CustomNativeSelect
                                                                        options={BUTTONS_COUNT_ENUM.filter(option => {
                                                                            if (isActionButtonsLayout) {
                                                                                if (option.value === 0) {
                                                                                    return false;
                                                                                }
                                                                            } else {
                                                                                if (option.value === 3) {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                            return true;
                                                                        })}
                                                                        defaultValue={new_ui[btns_mod_key].length}
                                                                        itemName={"tooltips_buttons"}
                                                                        handleChange={(event) => {
                                                                            let existing_btns = new_ui[btns_mod_key];
                                                                            let buttons = [];
                                                                            const btns_count = event.target.value;
                                                                            if (existing_btns.length > btns_count) {
                                                                                buttons = existing_btns.slice(0, btns_count)
                                                                            } else {
                                                                                for (let i = 0; i < btns_count; i++) {
                                                                                    if (isActionButtonsLayout) {
                                                                                        buttons.push({
                                                                                            ...ACTION_BUTTON_CONFIG,
                                                                                            "__id": generateShortID()
                                                                                        })
                                                                                    } else {
                                                                                        buttons.push({
                                                                                            ...BUTTON_CONFIG,
                                                                                            "__id": generateShortID()
                                                                                        })
                                                                                    }
                                                                                }
                                                                            }
                                                                            this.nullifySelectedModule();
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                [btns_mod_key]: buttons
                                                                            }, currentStep)
                                                                        }}
                                                                    />
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                label={"Dismiss on Touch Outside"}
                                                                control={
                                                                    <Checkbox
                                                                        color={"primary"}
                                                                        checked={dismiss_outside_touch}
                                                                        onChange={(e) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                dismiss_outside_touch: e.target.checked,
                                                                            }, currentStep)
                                                                        }}
                                                                        disableRipple
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                label={"Enable Animation"}
                                                                control={
                                                                    <Checkbox
                                                                        color={"primary"}
                                                                        checked={new_ui.animation.enabled}
                                                                        onChange={(e) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                animation: {
                                                                                    ...new_ui.animation,
                                                                                    enabled: e.target.checked,
                                                                                },
                                                                            }, currentStep)
                                                                        }}
                                                                        disableRipple
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionModuleConfig>}
                                                {action_class === ACTION_CLASSES.NUDGE &&
                                                <ExpansionModuleConfig title={"Nudge Configuration"} defaultExpanded>
                                                    <Grid container>
                                                        <Grid item xs={2} md={2}>
                                                            <ModuleConfigSection title={"Color"}>
                                                                <CustomColorPickerModule
                                                                    color={new_ui.bg_color}
                                                                    handleColorChange={(color) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            bg_color: color.hex
                                                                        }, currentStep)
                                                                    }}/>
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                        <Grid item xs={4} md={4}>
                                                            <ModuleConfigSection title={"Position"}>
                                                                <CustomNativeSelect
                                                                    options={NUDGE_POSITION_ENUM}
                                                                    defaultValue={new_ui.position}
                                                                    itemName={"nudge_position"}
                                                                    handleChange={(event) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            position: (event.target.value).toLowerCase()
                                                                        }, currentStep)
                                                                    }}
                                                                />
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                        <Grid item xs={4} md={4}>
                                                            <ModuleConfigSection title={"Animation"}>
                                                                <CustomNativeSelect
                                                                    options={NUDGE_ANIMATION_ENUM}
                                                                    defaultValue={new_ui.animation}
                                                                    itemName={"nudge_animation"}
                                                                    handleChange={(event) => {
                                                                        updateInNewUIWrapper({
                                                                            ...new_ui,
                                                                            animation: (event.target.value).toLowerCase()
                                                                        }, currentStep)
                                                                    }}
                                                                />
                                                            </ModuleConfigSection>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionModuleConfig>}
                                                { /* InApp logic start from here */}
                                                {
                                                    action_class === ACTION_CLASSES.INAPP
                                                    && !isCustomHTMLTemplate(action_class, layout_type)
                                                    && <ExpansionModuleConfig
                                                        title={"Container Configuration"}
                                                        defaultExpanded
                                                        key={"inapp_step" + currentStep}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={4} md={4}>
                                                                <ModuleConfigSection title={"Background Color"}>
                                                                    <CustomColorPickerModule
                                                                        color={new_ui.bg_color}
                                                                        handleColorChange={(color) => {
                                                                            updateInNewUIWrapper({
                                                                                ...new_ui,
                                                                                bg_color: color.hex
                                                                            }, currentStep)
                                                                        }}/>
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                            { /* Border Radius is only meant for Header and Footer layout types */}
                                                            {
                                                                (new_ui.layout_type === INAPP_LAYOUT_TYPES.HEADER || new_ui.layout_type === INAPP_LAYOUT_TYPES.FOOTER)
                                                                && <Grid item xs={4} md={4}>
                                                                    <ModuleConfigSection title={
                                                                        new_ui.layout_type === INAPP_LAYOUT_TYPES.FOOTER ? "Border Top Radius" : "Border Bottom Radius"
                                                                    }>
                                                                        <CustomNativeSelect
                                                                            options={BORDER_RADIUS_ENUM}
                                                                            defaultValue={new_ui.border_radius}
                                                                            itemName={"style"}
                                                                            handleChange={(e) => this.handleOnUpdate(Number(e.target.value), updateInNewUIWrapper, 'border_radius')}
                                                                        />
                                                                    </ModuleConfigSection>
                                                                </Grid>
                                                            }
                                                            { /* Maximum Height is only meant for Header and Footer layout types. Also, indicates how much maximum height InApp can take when it gets rendered in mobile */}
                                                            {
                                                                (new_ui.layout_type === INAPP_LAYOUT_TYPES.HEADER || new_ui.layout_type === INAPP_LAYOUT_TYPES.FOOTER)
                                                                && <Grid item xs={4} md={4}>
                                                                    <ModuleConfigSection title={"Maximum Height"}>
                                                                        <CustomNativeSelect
                                                                            options={MAX_HEIGHT_ENUM}
                                                                            defaultValue={new_ui.html_config.height}
                                                                            itemName={"style"}
                                                                            handleChange={(e) => this.handleOnUpdate(Number(e.target.value), updateInNewUIWrapper, 'height')}
                                                                        />
                                                                    </ModuleConfigSection>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={10} md={10}>
                                                                <CustomActionField
                                                                    key={"BgImage"}
                                                                    title={"Background Image"}
                                                                    dataKey={"bg_image"}
                                                                    placeholder={"https://image_url"}
                                                                    value={new_ui.bg_image}
                                                                    onActionFieldValueChange={
                                                                        (value) => this.handleOnUpdate(value, updateInNewUIWrapper, 'bg_image')
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        { /* Need to show only when Background Image is mentioned */}
                                                        {
                                                            new_ui.bg_image !== ""
                                                            && <Grid container>
                                                                <Grid item xs={10} md={10}>
                                                                    <ModuleConfigSection
                                                                        title={"Image Size"}>
                                                                        <ButtonGroup buttons={DEFAULT_IMAGE_SIZE_TYPES}
                                                                                     selectedIndex={DEFAULT_IMAGE_SIZE_TYPES.indexOf(capitalizeEachWord(new_ui.image_size))}
                                                                                     onButtonSelected={(index) => this.handleOnUpdate(DEFAULT_IMAGE_SIZE_TYPES[index], updateInNewUIWrapper, 'image_size')}/>
                                                                    </ModuleConfigSection>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid container>
                                                            { /* When this gets checked, all text and button components will be hidden */}
                                                            <Grid item xs={12} md={6}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            color={"primary"}
                                                                            checked={new_ui.only_image}
                                                                            onChange={(e) => this.handleOnUpdate(e.target.checked, updateInNewUIWrapper, 'only_image')}
                                                                            value={"ImageOnly"}
                                                                        />
                                                                    }
                                                                    label="Only Image"
                                                                />
                                                            </Grid>
                                                            { /* */}
                                                            {
                                                                new_ui.only_image
                                                                && <Grid item xs={12} md={6}>
                                                                    <CustomActionField
                                                                        key={"ClickAction"}
                                                                        title={"On Click/Touch"}
                                                                        dataKey={"touch_redirect_url"}
                                                                        placeholder={"https://custom_url"}
                                                                        value={new_ui.touch_redirect_url}
                                                                        onActionFieldValueChange={
                                                                            (value) => this.handleOnUpdate(value, updateInNewUIWrapper, 'touch_redirect_url')
                                                                        }/>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        {
                                                            (new_ui.layout_type === INAPP_LAYOUT_TYPES.HEADER || new_ui.layout_type === INAPP_LAYOUT_TYPES.FOOTER)
                                                            && <Grid container>
                                                                { /* Indicates whether to show dimmed background or not (Only for Header and Footer layout types) */}
                                                                <Grid item xs={12} md={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                color={"primary"}
                                                                                checked={new_ui.window_attributes.dimmed_background}
                                                                                onChange={(e) => this.handleOnUpdate(e, updateInNewUIWrapper, 'dimmed_background')}
                                                                                value={"DimmedBackground"}
                                                                            />
                                                                        }
                                                                        label="Dimmed Background"
                                                                    />
                                                                </Grid>
                                                                { /* Flag to indicate whether InApp should be dismissed when an outside touch happens */}
                                                                <Grid item xs={12} md={6}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                color={"primary"}
                                                                                checked={new_ui.window_attributes.dismiss_outside_touch}
                                                                                onChange={(e) => this.handleOnUpdate(e, updateInNewUIWrapper, 'dismiss_outside_touch')}
                                                                                value={"DismissOutsideTouch"}
                                                                            />
                                                                        }
                                                                        label="Dismiss Outside Touch"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </ExpansionModuleConfig>
                                                }
                                                { /* This is shown only when Custom HTML Template is selected */}
                                                {
                                                    action_class === ACTION_CLASSES.INAPP
                                                    && isCustomHTMLTemplate(action_class, layout_type)
                                                    && <ExpansionModuleConfig title={"Container Configuration"}
                                                                              defaultExpanded
                                                                              key={"inapp_step" + currentStep}>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <ModuleConfigSection title={"Custom HTML"}>
                                                                    <HTMLEditor
                                                                        height={"400px"}
                                                                        value={new_ui.html_config.data}
                                                                        handleChange={(data) => this.handleOnUpdate(data, updateInNewUIWrapper, 'data')}
                                                                    />
                                                                </ModuleConfigSection>
                                                            </Grid>
                                                            { /* InApp should consider either Custom HTML or URL, not both */}
                                                            <Grid item xs={12}>
                                                                <CustomActionField
                                                                    key={"URL"}
                                                                    style={{marginTop: '15px'}}
                                                                    title={"Load HTML from URL"}
                                                                    dataKey={"url"}
                                                                    placeholder={"https://example.com/index.html"}
                                                                    value={new_ui.html_config.url}
                                                                    onActionFieldValueChange={
                                                                        (value) => this.handleOnUpdate(value, updateInNewUIWrapper, 'url')
                                                                    }/>
                                                            </Grid>
                                                        </Grid>

                                                    </ExpansionModuleConfig>
                                                }

                                                {
                                                    selectedModule
                                                    && <ModulesConfigurator
                                                        {...this.props}
                                                        selectedConfig={selectedConfig}
                                                        currentCampaignStep={currentStep}
                                                        selectedModule={selectedModule}
                                                        /* Overriding the property to make sure it takes
                                                        * local wrapper function instead of default */
                                                        updateInNewUI={updateInNewUIWrapper}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>
                                    </DummyInsetContainer>
                                </div>
                            </Grid>
                        </Grid>)}
                </div>
                }
            </div>
        )
    }
}

export default withStyles(page2_styles)(Page2);