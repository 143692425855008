import React, {Component, Fragment} from 'react';
import Switch from '../Switch';
import moment from 'moment';
import {Grid, TextField, Tooltip} from "@material-ui/core";
import FormSelect from "../MaterialUi/FormSelect";
import ToggleSwitch from "../MaterialUi/ToggleSwitch";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TimePicker from "../MaterialUi/TimePicker";
import MultiSelect from "../MaterialUi/MultiSelect";
import CheckboxGroup from "../MaterialUi/CheckboxGroup";
import DateRangePicker from '../DateRangePicker';
import ContextForm from "../Context";
import {UserAndSessionProperties} from "../../../containers/Root/Application/RequireAuth/Dashboard/Segments/NewSegment/components";
import Dialog from "../MaterialUi/Dialog";
import Snackbar from "../MaterialUi/Snackbar";
import NumberField from "../MaterialUi/NumberField";
import {normalizedDateTime} from "../../../utils";
import Box from "../Box";
import InfoHelper from "../InfoHelper";
import {APP_PLATFORMS, LOGICAL_OPERATORS} from "../../../constants";


const TRIGGER_ENUM = {
    "App Event": "app_event",
    //"System Event": "system_event",
    //"User Event": "user_event",
    "Activity Event": "activity_event",
    "App Start": "app_start",
    //"Observation": "observation"
};

const TRIGGER_OPTIONS = Object.keys(TRIGGER_ENUM).map(o => ({key: TRIGGER_ENUM[o], label: o}));

const INITIAL_RESET_CONFIG = {
    enable: false,
    event_type: "",
    activity: "",
    details: {
        name: "",
        additional_info: {}
    }
};

export class ResetSelector extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_RESET_CONFIG;
    }

    componentWillMount() {
        //this.handleUpdate();
    }

    handleUpdate = () => {
        //this.props.handleUpdate({terminate_info: this.state});
    };

    render() {
        const {enable, event_type} = this.state;
        return (
            <Grid container style={{marginTop: 10}}>
                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>

                        <ToggleSwitch handleChange={
                            enable => this.setState({enable}, this.handleUpdate)
                        } label="Enable" checked={enable}/>

                        {
                            enable && <FormSelect
                                options={TRIGGER_OPTIONS}
                                value={event_type}
                                label="Select Trigger"
                                handleUpdate={(event_type) => this.setState({event_type}, this.handleUpdate)}
                                idKey="key"
                                nameKey="label"
                            />
                        }

                        {
                            enable && <FormSelect
                                value={this.state.activity}
                                options={[]}
                                label="Select Activity"
                                handleUpdate={(activity) => this.setState({activity}, this.handleUpdate)}
                                idKey="key" nameKey="label"
                            />
                        }

                    </Grid>
                </Grid>
            </Grid>
        )

    }

}

export class ValiditySelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validity: {
                start_date: moment(),
                end_date: moment()
            },
            time_limits_in_day: false,
            time_limits: {
                start_time: moment(),
                end_time: moment()
            }
        }
    }

    componentWillMount() {
        this.handleUpdate();
    }

    handleDateChange = (start, end) => {
        this.setState({validity: {start_date: start, end_date: end}});
    };

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render() {
        const {
            validity: {start_date, end_date},
            time_limits_in_day, time_limits: {start_time, end_time}
        } = this.state;
        return (
            <Grid container style={{marginTop: 10}}>
                <Grid item xs={6} xl={6} lg={6} md={6} sm={6}>
                    <Typography variant="subtitle1">Show between: </Typography>
                    <div style={{display: 'flex'}}>
                        <Fragment>
                            <DateRangePicker
                                inUTC
                                single
                                date={moment(start_date)}
                                handleChange={(start_date) => this.setState({
                                    validity: {
                                        ...this.state.validity,
                                        start_date
                                    }
                                })}
                                disableBefore
                            />
                            &nbsp;
                            <DateRangePicker
                                inUTC
                                single
                                date={moment(end_date)}
                                handleChange={(end_date) => this.setState({
                                    validity: {
                                        ...this.state.validity,
                                        end_date
                                    }
                                })}
                                disableBefore
                            />
                        </Fragment>
                    </div>

                    <Divider style={{margin: "20px 0 0"}}/>

                    <ToggleSwitch handleChange={
                        time_limits_in_day => this.setState({time_limits_in_day}, this.handleUpdate)
                    } label="Time limits in day" checked={time_limits_in_day}/>

                    {
                        time_limits_in_day &&
                        <div style={{display: 'flex'}}>
                            <TimePicker value={start_time} handleChange={(start_time) => this.setState({
                                time_limits: {
                                    ...this.state.time_limits,
                                    start_time
                                }
                            })}/>
                            &nbsp;
                            <TimePicker value={end_time} handleChange={(end_time) => this.setState({
                                time_limits: {
                                    ...this.state.time_limits,
                                    end_time
                                }
                            })}/>
                        </div>
                    }
                </Grid>
            </Grid>
        )

    }

}

const VALIDITY_ENUM = {
    SESSION: "SESSION",
    OVERALL: "OVERALL"
};

const VALIDITY_DATA = [
    {name: "PER SESSION", value: VALIDITY_ENUM.SESSION},
    {name: "OVERALL", value: VALIDITY_ENUM.OVERALL}
];

const RECURRING_ENUM = {
    HOUR: "HOUR",
    DAY: "DAY",
    WEEK: "WEEK",
};

const SCHEDULING_ENUM = {
    NOW: "NOW",
    LATER: "LATER"
};

export class ConditionSelector extends Component {

    constructor(props) {
        super(props);
        this.state = this._propToState();
    }

    _propToState = () => {
        const {
            validity: {start_date, end_date}, frequency, terminate_info,
            time_limits_in_day, time_limits: {start_time, end_time},
            scheduling: {schedule_date, schedule_time, schedule, recur, isRecurEnabled, step, rollSegment, rollInterval}
        } = this.props;
        return {
            validity: {
                start_date: start_date,
                end_date: end_date,
            }, frequency, terminate_info,
            time_limits_in_day, time_limits: {
                start_time: moment(start_time, "HH:mm"),
                end_time: moment(end_time, "HH:mm"),
            },
            scheduling: {
                schedule_date: moment(schedule_date, "YYYY-MM-DD"),
                schedule_time: moment(schedule_time, "HH:mm"),
                schedule: schedule,
                recur: recur,
                rollSegment: rollSegment,
                rollInterval: rollInterval,
                isRecurEnabled: isRecurEnabled,
                step: step
            }
        };
    };

    _stateToProps = () => {
        const {
            frequency, validity, time_limits_in_day,
            time_limits: {start_time, end_time}, terminate_info,
            scheduling:{schedule_date, schedule_time, schedule, recur, step, isRecurEnabled, rollSegment, rollInterval}
        } = this.state;
        return {
            time_limits_in_day, frequency, terminate_info,
            validity,
            time_limits: {
                start_time: start_time.format("HH:mm"),
                end_time: end_time.format("HH:mm")
            },
            scheduling: {
                schedule_date: schedule_date.format("YYYY-MM-DD"),
                schedule_time: schedule_time.format("HH:mm"),
                schedule: schedule,
                isRecurEnabled: isRecurEnabled,
                rollSegment: rollSegment,
                rollInterval: rollInterval,
                recur: recur,
                step: step
            }
        };
    };

    componentWillMount() {
        //
    }

    handleUpdate = () => {
        this.props.handleUpdate(this._stateToProps());
    };

    render() {
        const {inEditMode, enableAutoDismiss=true} = this.props;
        const {
            frequency: {count, time_interval}, frequency,
            validity: {start_date, end_date}, validity,
            time_limits_in_day,
            time_limits: {start_time, end_time}, time_limits,
            terminate_info: {auto_dismiss, duration} = {}, terminate_info,
            scheduling:{schedule_date, schedule_time, schedule, recur, isRecurEnabled, step}, scheduling
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return (
            <Grid container spacing={24}>
                <Grid item xs>
                    <Box title={"Schedule Message"}>
                        <Switch
                            data={Object.values(SCHEDULING_ENUM)}
                            disabled={disabled}
                            value={schedule}
                            handleChange={(schedule) => this.setState({
                                scheduling:{
                                    ...scheduling,
                                    schedule
                                }
                            }, this.handleUpdate)}
                        />
                        {
                            (schedule === SCHEDULING_ENUM.LATER) && <div>
                                <Typography component="span" type="subheading">
                                    Show Message from &nbsp;
                                    <div style={{display: 'inline-block'}}>
                                        <DateRangePicker
                                            inUTC
                                            single
                                            small
                                            disabled={disabled}
                                            disableBefore
                                            anchorDirection="left"
                                            date={moment(start_date)}
                                            handleChange={start_date => this.setState({
                                                validity: {
                                                    ...validity,
                                                    start_date
                                                },
                                                scheduling: {
                                                    ...scheduling,
                                                    schedule_date
                                                }
                                            }, this.handleUpdate)}
                                            disableFuture={false}
                                        />
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <TimePicker style={{width: 130}} margin="dense" disabled={disabled}
                                                value={schedule_time}
                                                handleChange={schedule_time => this.setState({
                                                    scheduling:{
                                                        ...scheduling,
                                                        schedule_time
                                                    }
                                                }, this.handleUpdate)}/>
                                </Typography>

                            </div>
                        }
                        <br/>

                        <ToggleSwitch handleChange={
                            isRecurEnabled => this.setState({
                                scheduling: {
                                    ...scheduling,
                                    isRecurEnabled,
                                    rollSegment: false,
                                    rollInterval: 0
                                }
                            }, this.handleUpdate)
                        } label="Recur Message" checked={isRecurEnabled} disabled={disabled}/>
                        {
                            isRecurEnabled && <Switch
                                data={Object.values(RECURRING_ENUM)}
                                disabled={disabled}
                                value={recur}
                                handleChange={(recur) => this.setState({
                                    scheduling:{
                                        ...scheduling,
                                        recur
                                    }
                                }, this.handleUpdate)}
                            />
                        }
                        {
                            isRecurEnabled &&
                            <div style={{display: "flex"}}>
                                <TextField
                                    type="number"
                                    required
                                    placeholder="1"
                                    InputProps={{inputProps : { min:0 }}}
                                    value={step}
                                    label="Recur interval"
                                    onChange={e => this.setState({
                                        scheduling:{
                                            ...scheduling,
                                            step: Number(e.target.value)
                                        }
                                    }, this.handleUpdate)}
                                />
                                <div style={{marginTop: 20, marginLeft: 2}}>
                                    <InfoHelper>Next Schedule date is current Schedule date plus this step</InfoHelper>
                                </div>
                            </div>
                        }
                        <br/>
                        <br/>
                        <br/>
                        <Typography component="span">
                            Show Message till  &nbsp;&nbsp;
                            <div style={{display: 'inline-block'}}>
                                <DateRangePicker
                                    inUTC
                                    single
                                    small
                                    disabled={disabled}
                                    disableBefore
                                    date={moment(end_date)}
                                    handleChange={end_date => this.setState({
                                        validity: {
                                            ...validity,
                                            end_date
                                        }
                                    }, this.handleUpdate)}
                                    disableFuture={false}
                                />
                            </div>
                        </Typography>
                    </Box>
                    <br/>
                    <ToggleSwitch handleChange={
                        time_limits_in_day => this.setState({time_limits_in_day}, this.handleUpdate)
                    } label="Show at a specific time" checked={time_limits_in_day} disabled={disabled}/>
                    {
                        time_limits_in_day &&
                        <Typography style={{lineHeight: '2.2em'}} component="span" type="subheading">
                            Hours of the day between &nbsp;
                            <div style={{display: 'inline-block'}}>
                                <TimePicker disabled={disabled} value={start_time}
                                            handleChange={start_time => this.setState({
                                                time_limits: {
                                                    ...time_limits,
                                                    start_time
                                                }
                                            }, this.handleUpdate)}/>
                            </div>
                            &nbsp; and &nbsp;
                            <div style={{display: 'inline-block'}}>
                                <TimePicker disabled={disabled} value={end_time}
                                            handleChange={end_time => this.setState({
                                                time_limits: {
                                                    ...time_limits,
                                                    end_time
                                                }
                                            }, this.handleUpdate)}/>
                            </div>
                        </Typography>
                    }
                    <br/>
                    <Typography style={{lineHeight: '4.5em'}} component="span" type="subheading">
                        Limit to displaying &nbsp;
                        <TextField
                            style={{maxWidth: 70}}
                            type="number"
                            required
                            placeholder="8000"
                            value={count}
                            label="Time(s)"
                            onChange={e => this.setState({
                                frequency: {
                                    ...frequency,
                                    count: Number(e.target.value)
                                }
                            }, this.handleUpdate)
                            }
                            disabled={disabled}
                        /> &nbsp; time(s)
                    </Typography>
                    {count > 1 && <Fragment>
                        <br/>
                        <Typography style={{lineHeight: '4.5em', display: 'flex'}} component="span" type="subheading">
                            With a time interval of &nbsp;
                            <NumberField
                                style={{maxWidth: 70}}
                                type="number"
                                required
                                placeholder="2000"
                                value={time_interval}
                                label="Duration"
                                handleChange={time_interval => this.setState({
                                    frequency: {
                                        ...frequency,
                                        time_interval
                                    }
                                }, this.handleUpdate)
                                }
                                disabled={disabled}
                                metricType="ms"
                            />
                        </Typography>
                    </Fragment>}
                    <Switch
                        data={VALIDITY_DATA}
                        value={frequency.validity}
                        handleChange={
                            validity =>
                                this.setState({
                                    frequency: {
                                        ...frequency,
                                        validity
                                    }
                                }, this.handleUpdate)
                        }
                        disabled={disabled}
                    />
                    {enableAutoDismiss && <div>
                        <br/>
                        <Typography style={{lineHeight: '5em', display: 'flex'}} component="span" type="subheading">
                            <ToggleSwitch disabled={disabled} handleChange={
                                auto_dismiss => this.setState({
                                    terminate_info: {
                                        ...terminate_info,
                                        auto_dismiss
                                    }
                                }, this.handleUpdate)
                            } label="" checked={auto_dismiss}/>
                            Auto dismiss after &nbsp;
                            {
                                auto_dismiss && <NumberField
                                    style={{maxWidth: 70}}
                                    required
                                    placeholder="30000"
                                    value={duration}
                                    label="Duration"
                                    handleChange={duration => this.setState({
                                        terminate_info: {
                                            ...terminate_info,
                                            duration
                                        }
                                    }, this.handleUpdate)
                                    }
                                    margin="dense"
                                    disabled={disabled}
                                    metricType="ms"
                                />
                            }
                        </Typography>
                    </div>
                    }
                </Grid>
            </Grid>
        )

    }

}

export class GeneralSelector extends Component {

    constructor(props) {
        super(props);
        const {appState: {app: {basic_info: {platform: appPlatform}}}} = this.props;
        const {name = "", description = "", platform, ...rest} = props.meta || {};
        this.state = {
            ...rest,
            platform: platform ? platform : (appPlatform === APP_PLATFORMS.omni ? APP_PLATFORMS.android : appPlatform),
            name, description
        };
    }

    componentWillMount() {
        //this.handleUpdate();
    }

    handleUpdate = () => {
        this.props.handleUpdate({meta: {...this.state}});
    };

    render() {
        const {inEditMode, withPlatform} = this.props;
        const {name, description, platform} = this.state;
        const {appState: {app: {basic_info: {platform: appPlatform}}}} = this.props;
        const disabled = !inEditMode && this.props.disabled;
        return (
            <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                    <TextField
                        type="text"
                        required
                        placeholder=""
                        value={name}
                        label="Name"
                        onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                    />
                    <TextField
                        type="text"
                        placeholder="Description (Optional)"
                        value={description}
                        label="Description"
                        onChange={e => this.setState({description: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                {
                    withPlatform
                    && <>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={4}>
                            <Typography>
                                Select Platform
                            </Typography>
                            <Switch
                                data={appPlatform === APP_PLATFORMS.omni ? [APP_PLATFORMS.android, APP_PLATFORMS.ios] : [appPlatform]}
                                disabled={disabled}
                                value={platform}
                                handleChange={(platform) => this.setState({
                                    platform
                                }, this.handleUpdate)}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        )

    }

}

const AUDIENCE_ENUM = {
    FTU: "FTU",
    ALL: "ALL"
};

const AUDIENCE_OPTIONS = [
    {value: AUDIENCE_ENUM.FTU, label: "First Time Users"},
    {value: AUDIENCE_ENUM.ALL, label: "All Users"}
];

export class AudienceSelector extends Component {

    constructor(props) {
        super(props);
        const {
            audience_type = AUDIENCE_ENUM.ALL, attributes = {},
            segment_id, config_type, type, start_time, end_time,
            limited, overall_cfg: {ret_day, events, toggleRetDay}, scheduling
        } = props;
        this.state = {
            audience_type, attributes, segment_id,
            config_type, type, start_time, end_time,
            limited, ret_day: {from: ret_day.from || 0, to: ret_day.to || 7}, scheduling,
            selectedEvents: events.map(eventItem => eventItem.name),
            toggleRetDay: toggleRetDay
        };
    }

    componentWillMount() {
        /*const { handleQueryUpdate } = this.props;
        const { attributes } = this.state;
        handleQueryUpdate(attributes);*/
    }

    handleUpdate = () => {
        const {audience_type, segment_id, ret_day, selectedEvents, scheduling, toggleRetDay, ...others} = this.state;
        this.props.handleUpdate({
            overall_cfg: {
                ret_day: toggleRetDay ? ret_day : {},
                events: selectedEvents.map(eventItem => ({
                    name: eventItem,
                    op: LOGICAL_OPERATORS.LT,
                    value: 1
                })),
                toggleRetDay
            },
            scheduling,
            audience: {
                ...others,
                audience_type,
                segment_id: audience_type === AUDIENCE_ENUM.ALL ? segment_id : undefined
            }
        });
    };

    render() {
        const {appState: {appSegments = [], appEvents = []}, inEditMode, requireRetentionConfig} = this.props;
        const {
            audience_type, segment_id, start_time, end_time, limited = {}, ret_day= {
                from:0,
                to: 0
            }, selectedEvents, scheduling = {}, toggleRetDay,
            scheduling: {rollSegment = false, rollInterval = 0, isRecurEnabled = false}
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        const hasUserLimit = Boolean(limited.count);
        return (
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={12} md={2}>
                        <MultiSelect
                            value={audience_type}
                            options={AUDIENCE_OPTIONS}
                            label="Select Users"
                            placeholder="Select Users"
                            handleChange={(audience_type) => this.setState({audience_type}, this.handleUpdate)}
                            single
                            disabled={disabled}
                        />
                    </Grid>
                    {
                        disabled && hasUserLimit && <Grid item xs={12} md>
                            <Typography variant="subtitle1" style={{lineHeight: '5em'}}>(Limited
                                to <strong>{limited.count}</strong>)</Typography>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={16}>
                    { isRecurEnabled &&
                        <Grid item xs={12}>
                            <ToggleSwitch
                                value={rollSegment}
                                checked={rollSegment}
                                disabled={disabled}
                                label={"Use segment as Rolling Segment"}
                                handleChange={(checked) => {
                                    this.setState({
                                        scheduling: {
                                            ...scheduling,
                                            rollSegment: checked
                                        }
                                    }, this.handleUpdate)
                                }}
                            />
                        </Grid>
                    }
                    {
                        audience_type === AUDIENCE_ENUM.ALL && <Grid item xs={12} md={4}>
                            <MultiSelect
                                value={segment_id}
                                options={appSegments.map(o => ({label: o.name, value: o._id}))}
                                label="Select Segment"
                                placeholder="Select Segment"
                                handleChange={segment_id => this.setState({segment_id}, this.handleUpdate)}
                                single
                                margin="normal"
                                disabled={disabled}
                            />
                        </Grid>
                    }
                    {segment_id && segment_id.length > 0 && !rollSegment && <Grid item xs={12} md={4}>
                        <div style={{marginTop: 24, display: 'flex'}}>
                            <Typography style={{lineHeight: '3em'}}
                                        variant="subtitle1">&nbsp; between &nbsp;</Typography>
                            <DateRangePicker
                                inUTC
                                small
                                anchorDirection="left"
                                startDate={moment(start_time)}
                                endDate={moment(end_time)}
                                handleChange={(start_time, end_time) => {
                                    this.setState({start_time, end_time}, this.handleUpdate);
                                }}
                                disabled={disabled}
                            />
                        </div>
                    </Grid>
                    }
                    {segment_id && segment_id.length > 0 && rollSegment && <Grid item xs={12} md={4}>
                        <div style={{marginTop: 24, display: 'flex'}}>
                            <Typography component="span" style={{lineHeight: '3em', display: 'inline-flex'}} variant="subtitle1">
                                &nbsp; in the last &nbsp;
                                <TextField
                                    style={{marginLeft: 16, marginTop: -4, maxWidth: 120}}
                                    type={"number"}
                                    label={"days"}
                                    required
                                    disabled={disabled}
                                    value={rollInterval}
                                    inputProps={{min: 0}}
                                    onChange={(e) => {
                                        const number = Number(e.target.value);
                                        if (!isNaN(number)) {
                                            this.setState({
                                                scheduling: {
                                                    ...scheduling,
                                                    rollInterval: number
                                                }
                                            }, this.handleUpdate)
                                        }
                                    }}/>
                            </Typography>
                        </div>
                    </Grid>
                    }
                </Grid>
                <Grid container spacing={16}>
                    <Typography component="span" style={{lineHeight: '4em', display: 'inline-flex', margin: '8px 8px'}}
                                variant="subtitle1">
                        Users who didn't perform &nbsp;
                        {
                            <MultiSelect
                                placeholder={"Select Event(s)"}
                                options={
                                    appEvents.map(o => ({label: o, value: o}))
                                }
                                value={selectedEvents}
                                label={"Event(s)"}
                                handleChange={eventsList => {
                                    this.setState({
                                        selectedEvents: eventsList
                                    }, this.handleUpdate)
                                }}
                                style={{display: 'inline-block', minWidth: 300, maxWidth: 800}}
                                disabled={disabled}
                            >
                            </MultiSelect>
                        }
                    </Typography>
                </Grid>
                <br/>
                <UserAndSessionProperties
                    noReset
                    onlyEquality
                    {...this.props}
                    disabled={disabled}
                    withoutBox
                    filterAPXProps_session
                    onQueryUpdate={attributes => {
                        this.setState({attributes}, this.handleUpdate);
                    }}
                />
                {
                    !disabled && <Grid container spacing={16}>
                        <Grid item xs={6} md={2}>
                            <ToggleSwitch
                                checked={hasUserLimit}
                                handleChange={checked => {
                                    this.setState({
                                        limited: {
                                            ...limited,
                                            count: checked ? 100 : undefined
                                        }
                                    }, this.handleUpdate)
                                }}
                                value={limited.count}
                                label="Limited users"
                            />
                        </Grid>
                        {
                            !disabled && hasUserLimit && <Grid item xs>
                                <TextField
                                    type="text"
                                    value={limited.count || 0}
                                    label="Max Users"
                                    onChange={e => {
                                        const number = Number(e.target.value);
                                        if (!isNaN(number)) {
                                            this.setState({limited: {...limited, count: number}}, this.handleUpdate)
                                        }
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>

                }
                {
                    <Grid item>
                        <ToggleSwitch
                            checked={toggleRetDay}
                            disabled={disabled}
                            handleChange={checked => {
                                this.setState({
                                    toggleRetDay: !toggleRetDay
                                }, this.handleUpdate)
                            }}
                            value={toggleRetDay}
                            label="Target Users based on Retention"
                        />
                    </Grid>
                }
                {
                    requireRetentionConfig && toggleRetDay && <Fragment>
                        <Typography component="span" style={{lineHeight: '3em', display: 'inline-flex', margin: '8px 0'}} variant="subtitle1">
                            Users retained between &nbsp;
                            {<TextField
                                disabled={disabled}
                                inputProps={{min: 0, max: 90}}
                                label={"Day"}
                                required
                                style={{marginRight: 12}}
                                type={"number"}
                                value={ret_day.from}
                                onChange={e => {
                                    const number = Number(e.target.value);
                                    if (!isNaN(number)) {
                                        this.setState({
                                            ret_day: {
                                                ...ret_day,
                                                from: number,
                                                to: number > ret_day.to ? number : ret_day.to
                                            }
                                        }, this.handleUpdate)
                                    }
                                }}
                            />}
                            and &nbsp;
                            {<TextField
                                disabled={disabled}
                                inputProps={{min: ret_day.from, max: 90}}
                                label={"Day"}
                                required
                                style={{marginLeft: 12}}
                                type={"number"}
                                value={(ret_day.to > ret_day.from ? ret_day.to : ret_day.from) || 0}
                                onChange={e => {
                                    const number = Number(e.target.value);
                                    if (!isNaN(number)) {
                                        this.setState({
                                            ret_day: {
                                                ...ret_day,
                                                to: number
                                            }
                                        }, this.handleUpdate)
                                    }
                                }}
                            />}
                        </Typography>
                    </Fragment>
                }
            </Fragment>
        )

    }

}

export class TestingSelector extends Component {


    constructor(props) {
        super(props);
        const {appState: {appTestDevices = []}} = props;
        const {enabled = false, devices = appTestDevices} = props.testing || {};
        this.state = {
            testing: {
                enabled: enabled,
                devices: devices
            }
        }
    }

    componentWillMount() {

    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };


    render() {
        const {inEditMode, appState: {appTestDevices = []}} = this.props;
        const {testing: {enabled, devices}, testing} = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return (
            <Grid container>
                <Grid item xs={12} md={4}>
                    <ExpansionPanel defaultExpanded={enabled} expanded={enabled}>
                        <ExpansionPanelSummary>
                            <ToggleSwitch
                                handleChange={(enabled) => this.setState({
                                    testing: {
                                        ...testing,
                                        enabled
                                    }
                                }, this.handleUpdate)}
                                label="Enable Testing"
                                checked={enabled}
                                disabled={disabled}
                            />
                        </ExpansionPanelSummary>
                        {
                            enabled &&
                            <ExpansionPanelDetails style={{flexDirection: 'column'}}>
                                <Typography variant="subtitle1">Select Devices</Typography>
                                <CheckboxGroup
                                    options={appTestDevices.map(o => ({label: o.model, value: o.id, ...o}))}
                                    value={devices}
                                    row={false}
                                    handleChange={(devices) => this.setState({
                                        testing: {
                                            ...testing,
                                            devices
                                        }
                                    }, this.handleUpdate)}
                                    disabled={disabled}
                                    labelRenderer={(o) => <Typography>{o.model} <Typography
                                        title={`${o.id} (${o.custom_id || "NA"})`}
                                        variant="caption">{o.id} {o.custom_id ? `(${o.custom_id})` : ''}</Typography></Typography>}
                                />
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )

    }

}

class Context extends Component {

    state = {
        currentContext: null,
        saveTriggered: false,
        name: ""
    };

    render() {
        const {currentContext, saveTriggered, name} = this.state;
        const {
            params: {appId},
            appState: {appContexts = []}, handleUpdate, conditions = [],
            saveContext, contexts: {save_context, save_context_failed},
            inEditMode
        } = this.props;
        const disabled = !inEditMode && this.props.disabled;
        return (
            <Fragment>
                {
                    !disabled && <Grid container spacing={16} justify="flex-end">
                        <Grid item xs={12} md={4}>
                            <MultiSelect
                                single
                                options={appContexts.map(o => ({label: o.name, value: o._id}))}
                                handleChange={contextId => {
                                    const currentContextId = contextId.length > 0 ? contextId : null;
                                    this.setState({currentContext: currentContextId});
                                    const currentContext = appContexts[appContexts.findIndex(o => o._id === currentContextId)];
                                    if (currentContext) {
                                        handleUpdate({conditions: currentContext.conditions || []});
                                    }
                                }}
                                label="Select from existing Contexts"
                                placeholder="Existing Contexts"
                                value={currentContext}
                                margin="normal"
                                clearable
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                }
                {
                    currentContext && conditions.length > 0 && <ContextForm {...this.props} />
                }
                {
                    !currentContext && <ContextForm {...this.props} />
                }
                <div style={{display: 'flex', justifyContent: 'flex-end', margin: '16px 0'}}>
                    <Button onClick={e => this.setState({saveTriggered: true})}
                            variant="contained"
                            color="primary"
                            disabled={conditions.length === 0}
                    >Save as New Context</Button>
                </div>
                <Dialog
                    status={saveTriggered}
                    handleConfirm={() => {
                        if (name && name.length > 0) {
                            saveContext(appId, {name, conditions});
                            this.setState({saveTriggered: false});
                        }
                    }}
                    title="Saving Confirmation"
                    handleClose={() => this.setState({saveTriggered: false})}
                    confirmLabel="Confirm"
                    allowCancelLabel="Cancel"
                >
                    <TextField
                        type="text"
                        required
                        placeholder="My Context"
                        value={name}
                        label="Context Name"
                        onChange={e => this.setState({name: e.target.value})}
                        margin="normal"
                        fullWidth
                    />
                </Dialog>
                {save_context && <Snackbar>Context saved successfully.</Snackbar>}
                {save_context_failed && <Snackbar>Saving Context failed, try again.</Snackbar>}
            </Fragment>
        )
    }

}

const INITIAL_ACTION_CONFIG = {
    audience: {
        audience_type: AUDIENCE_ENUM.ALL,
        attributes: {user: [], session: []},
        limited: {}
    },
    scheduling:{
        schedule: SCHEDULING_ENUM.NOW,
        schedule_date: moment().startOf('day').format('YYYY-MM-DD'),
        schedule_time: moment().format("HH:mm"),
        isRecurEnabled: false,
        rollSegment: false,
        rollInterval: 0,
        recur: RECURRING_ENUM.DAY,
        step: 1
    },
    testing: {
        enabled: false,
        devices: []
    },
    meta: {
        name: "",
        description: "",
        platform: APP_PLATFORMS.android,
    },
    validity: {
        start_date: moment().startOf('day').toISOString(),
        end_date: moment().add(1, 'day').startOf('day').toISOString()
    },
    frequency: {
        count: 1,
        time_interval: 1000,
        validity: VALIDITY_ENUM.OVERALL
    },
    terminate_info: {
        auto_dismiss: false,
        duration: 5000,
    },
    time_limits_in_day: false,
    time_limits: {
        start_time: moment().format("HH:mm"),
        end_time: moment().format("HH:mm")
    },
    conditions: [],
    overall_cfg: {
        ret_day: {},
        events: [],
        toggleRetDay: false
    },
    ui: {},
    uis: [],
    reset: INITIAL_RESET_CONFIG
};

const MESSAGE_CONFIGURATIONS = {
    GENERAL: "General",
    CONDITIONS: "Conditions",
    AUDIENCE: "Audience",
    CONTEXT: "Context",
    DESIGN: "Design",
    TESTING: "Testing"
};


export class ActionCreatorHolder extends Component {

    _id = Math.random();

    _steps = Object.keys(MESSAGE_CONFIGURATIONS);


    constructor(props) {
        super(props);
        this.completed = new Set();
        const {action = {}} = props;
        this.state = {
            ...INITIAL_ACTION_CONFIG,
            ...action,
            activeStep: 0
        }
    }

    componentWillMount() {
        const {handleQueryUpdate} = this.props;
        const {audience: {attributes} = {attributes: {user: [], session: []}}} = this.state;
        handleQueryUpdate(attributes);
    }

    handleUpdate = (state) => {
        this.setState(state, () => {
            const {activeStep, expanded, ...message} = this.state;
            this.props.handleUpdate({enabled: true, ...message});
        });
    };

    handleChange = panel => (event) => {
        this.setState({
            expanded: panel,
            activeStep: this._steps.indexOf(panel)
        });
    };

    handleNext = () => {
        this.completed.add(this.state.activeStep);
        this.setState({
            expanded: this._steps[this.state.activeStep + 1],
            activeStep: this.state.activeStep + 1
        });
    };

    handleBack = () => {
        this.setState({
            expanded: this._steps[this.state.activeStep - 1],
            activeStep: this.state.activeStep - 1
        });
    };

    handleStep = (index) => () => {
        this.setState({
            expanded: this._steps[index],
            activeStep: index
        });
    };

    render() {
        const {activeStep, meta, audience, overall_cfg} = this.state;
        const {children} = this.props;
        return (
            <div style={{width: '100%'}}>
                <Stepper nonLinear activeStep={activeStep}>
                    {
                        Object.values(MESSAGE_CONFIGURATIONS).map((label, index) =>
                            <Step key={label}>
                                <StepButton onClick={this.handleStep(index)}>{label}</StepButton>
                            </Step>
                        )
                    }
                </Stepper>
                <div style={{padding: '16px 0'}}>
                    {activeStep === 0 &&
                    <GeneralSelector {...this.props} meta={meta} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 1 &&
                    <ConditionSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 2 &&
                    <AudienceSelector {...this.props} {...audience} scheduling={this.state.scheduling} overall_cfg={overall_cfg} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 3 && <Context {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 4 && React.cloneElement(children, {...this.state, handleUpdate: this.handleUpdate})}
                    {activeStep === 5 &&
                    <TestingSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                </div>
                <div style={{display: 'flex', marginTop: 20}}>
                    <Button disabled={activeStep === 0} onClick={this.handleBack}>Back</Button>
                    <Button disabled={activeStep === 5} variant="contained" color="primary"
                            onClick={this.handleNext}>Next</Button>
                </div>
            </div>
        )
    }

}

ActionCreatorHolder.propTypes = {};