/**
 * Created by Araja Jyothi Babu on 13-Oct-16.
 */
import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logger from '../../../../../utils/Logger';
import { loginUser, forgotPassword, resetAuthReducer, checkUserExistence, logoutUser } from '../actions';
import LoginForm from '../components/LoginForm';
import { AUTH_ERROR_ENUM } from '../../../../../constants/EndPoints';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';

function mapStateToProps(state) {
    return {
        session: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        login: (userName, password) => dispatch(loginUser(userName, password)),
        handleForgotPassword: (email) => dispatch(forgotPassword(email)),
        resetAuth: () => dispatch(resetAuthReducer()),
        logout: () => dispatch(logoutUser()),
        checkUser: (user) => dispatch(checkUserExistence(user))
    };
}

class Login extends Component {

    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
    };

    componentWillMount(){
        const {session: { token, host } } = this.props;
        //For Demo only
        if(this.props.location.query.demo){
            window.localStorage.setItem("_isForDemo", true);
        }
        const { redirect } = this.props.location.query;
        if (Boolean(token)) {
            if(redirect){
                this.context.router.replace(redirect);
            } else {
                this.context.router.replace('/apps');
            }
        }else{
            //this.props.logout();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {token } = nextProps.session;
        const { redirect } = this.props.location.query;
        if (Boolean(token)) {
            if(redirect){
                this.context.router.replace(redirect);
            } else {
                this.context.router.replace('/apps');
            }
        } else{
            //this.props.logout();
        }
    }

    componentWillUnmount(){
        this.props.resetAuth();
    }

    render(){
        let reason = this.props.location.query.reason;
        return (
            <section style={{width: '100%'}}>
                { reason && <Snackbar>{ AUTH_ERROR_ENUM[reason] }</Snackbar> }
                <LoginForm {...this.props}/>
            </section>
        );
    }
}

Login.contextTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);