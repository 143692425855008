/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    OVERVIEW_PENDING, OVERVIEW_FAILED,
    SESSION_TIME_SERIES, DAU_TIME_SERIES,
    EVENT_ANALYSIS_FAILED, EVENT_ANALYSIS_PENDING,
    EVENT_ANALYSIS_EVENT_ATTRIBUTES, EVENT_ANALYSIS_EVENTS,
    EVENT_ANALYSIS_EVENT_COUNT, EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_SESSION_COUNT, EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_USER_COUNT, EVENT_ANALYSIS_EVENT_USER_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION,
    ALL_SESSIONS_COUNT_PENDING, ALL_SESSIONS_COUNT, ALL_SESSIONS_COUNT_FAILED,
    ALL_USERS_COUNT_PENDING, ALL_USERS_COUNT, ALL_USERS_COUNT_FAILED,
    EVENT_ANALYSIS_ATTRIBUTES, EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
    EVENT_ANALYSIS_EVENT_KPI_COUNT, RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
    ADD_EVENT_ANALYSIS_CURRENT_EVENT, DELETE_EVENT_ANALYSIS_CURRENT_EVENT,
    EVENT_ANALYSIS_RETENTION_PENDING, EVENT_ANALYSIS_RETENTION, EVENT_ANALYSIS_RETENTION_FAILED,
    EVENT_ANALYSIS_EVENT_KPIS_PENDING, EVENT_ANALYSIS_EVENT_KPIS, EVENT_ANALYSIS_EVENT_KPIS_FAILED,
    UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF,
    EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING, EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION,
    EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED,
    EVENT_ANALYSIS_EVENT_RETENTION_PENDING, EVENT_ANALYSIS_EVENT_RETENTION, EVENT_ANALYSIS_EVENT_RETENTION_FAILED,
    GET_TOP_EVENTS_PENDING, GET_TOP_EVENTS, GET_TOP_EVENTS_FAILED, RESET_EVENT_ANALYSIS,
    UPDATE_ATTRIBUTE_DISTRIBUTION_OF, UPDATE_EVENT_ANALYSIS_USER_GROUP, UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS,
    UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS, UPDATE_EVENT_ANALYSIS_GROUP_DAYS,
    CLEAN_EVENT_ANALYSIS, TIME_SPENT_PENDING, TIME_SPENT, TIME_SPENT_FAILED
} from './actionTypes';
import { CUSTOM_EVENT_ENUM } from './actionTypes';
import { USER_GROUP_ENUM} from '../../../../../../constants';
import { getGroupIdFromList } from '../../../../../../utils';
import {
    getSessionTimeSeriesAPI, getUsersTimeSeriesAPI,
    getEventsAPI, getEventAttributesAPI,
    getEventRelatedCount, getEventRelatedDistributions, getEventRelatedTimeSeries,
    getAllUsersCountAPI, getAllSessionsCountAPI, getAttributesAPI, getAttributeDistributionAPI,
    getRetentionAPI, getEventKPIsAPI, getEventRetentionAPI, getEventRepeatedRetentionAPI,
    getTopEventsAPI, getTimeSpentAPI
} from './api';
import {updateUserGroupId, setDefaultFilters} from '../../Filters/actions';
import {getGroupTypeFromList} from "../../../../../../utils/index";
import logger from "../../../../../../utils/Logger";

/**
 *
 * @param state
 * @param filters
 * @param event
 * @param distributionFilters
 * @returns {{user: *, session: *, event: *}}
 */
function toGenericFilterModel(state = {}, filters = {}, event, distributionFilters = {}) {
    const { attributes: { user = [], session = [] } = {} } = state.app;
    const userAttributes = Object.keys(filters).filter(attribute => user.includes(attribute));
    const sessionAttributes = Object.keys(filters).filter(attribute => session.includes(attribute));
    const toAttributeFilterModel = (attributes, map = filters) => attributes.map(attribute => ({name: attribute, operator: "EQ", value: map[attribute]}));
    return {
        user: toAttributeFilterModel(userAttributes),
        session: toAttributeFilterModel(sessionAttributes),
        event: event ? [
            {
                name: event,
                count: { value: 1, operator: "GTE" },
                attributes: toAttributeFilterModel(Object.keys(distributionFilters), distributionFilters)
            }
        ] : []
    };
}

/**
 *
 * @param state
 * @param others
 * @returns {{group: string, filters: {user, session, event}}}
 */
function makeFilters(state, others = {}) {
    let { group = USER_GROUP_ENUM.ALL_USERS, filters, distributions_filters = {}, days: { startDay, endDay } } = state.eventAnalysis;
    const genericFilters = toGenericFilterModel(state, filters, others.event, distributions_filters[others.event]);
    return {
        ...state.filters, ...others, group, filters: genericFilters, startDay, endDay
    };
}

/**
 *
 * @param timeSeriesType
 * @param groupId
 * @param appId
 * @returns {function(*, *): *}
 */
export const getOverviewUserTimeSeries = (timeSeriesType, groupId, appId) => {
    return (dispatch, getState) => {
        dispatch(updateUserGroupId(groupId));
        const filters = makeFilters(getState(), {of: CUSTOM_EVENT_ENUM.users});
        return dispatch({
            types: [
                OVERVIEW_PENDING,
                timeSeriesType,
                OVERVIEW_FAILED
            ],
            payload: {
                promise: getUsersTimeSeriesAPI(getState().auth, appId, filters)
                 .then((res) => {
                     return res;
                 }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param timeSeriesType
 * @param groupId
 * @param appId
 * @returns {function(*, *)}
 */
export const getOverviewSessionTimeSeries = (timeSeriesType, groupId, appId) => {
    return (dispatch, getState) => {
        dispatch(updateUserGroupId(groupId));
        const filters = makeFilters(getState(), {of: CUSTOM_EVENT_ENUM.sessions});
        return dispatch({
            types: [
                OVERVIEW_PENDING,
                timeSeriesType,//FIXME: Overview uses same actionTypes, uses different api's though.
                OVERVIEW_FAILED
            ],
            payload: {
                promise: getSessionTimeSeriesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getEvents = (appId) => {
    return (dispatch, getState) => {
        const filters = makeFilters(getState());
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENTS,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, events) => {
                    /*if(Array.isArray(events) && events.length > 0) {
                        dispatch(updateSegmentEvent(appId, events[0]));
                        dispatch(getEventData(appId, events[0]));
                    }*/
                }
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const filters = makeFilters(getState(), {event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_ATTRIBUTES,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    if(Array.isArray(attributes)) { // not need as of now
                        //dispatch(getEventAttributeDistributions(appId, event, attributes));
                    }else{
                        console.error("No event attributes fetched..!")
                    }
                }
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventCount = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.event;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_COUNT,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedCount(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*=, *): *}
 */
export const getEventKPIs = (appId, event) => {
    //FIXME: THIS AT THE API LEVEL : Temporary despicable 'HACK'
    return (dispatch, getState) => {
        const filters = makeFilters(getState(), {event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_EVENT_KPIS_PENDING,
                EVENT_ANALYSIS_EVENT_KPIS,
                EVENT_ANALYSIS_EVENT_KPIS_FAILED
            ],
            payload: {
                promise: getEventKPIsAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    data.forEach(kpi => {
                        dispatch(getEventKPICount(appId, event, kpi));
                    });
                }
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @param kpi
 * @returns {function(*, *): *}
 */
export const getEventKPICount = (appId, event, kpi) => {
    return (dispatch, getState) => {
        const of = kpi;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_KPI_COUNT,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedCount(getState().auth, appId, filters, event)
                    .then((res) => {
                        if(event === kpi) return "NA"; //FIXME: Not applicable if event and kpi are same
                        return res;
                    }),
            },
            meta: {
                kpi: kpi,
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventUserCount = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_USER_COUNT,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedCount(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventSessionCount = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.sessions;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_SESSION_COUNT,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedCount(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @param attributes
 * @param all
 * @param of
 * @returns {Function}
 */
export const getEventAttributeDistributions = (appId, event, attributes, all = false, of = CUSTOM_EVENT_ENUM.users) => {
    return (dispatch, getState) => {
        let requiredAttributes = [];
        const existingAttributes = Object.keys(getState().eventAnalysis.current_events[event].distributions);
        if(all) {
            dispatch(resetEventAttributeDistributions(event)); //cleaning distributions object
            requiredAttributes = attributes || existingAttributes;
        }else{
            requiredAttributes = attributes.filter(a => existingAttributes.indexOf(a) === -1);
            if(requiredAttributes.length === 0){
                let removableAttributes = existingAttributes.filter(a => attributes.indexOf(a) === -1);
                dispatch(resetEventAttributeDistributions(event, removableAttributes));
            }
        }
        requiredAttributes.forEach(attribute => {
            dispatch(getEventAttributeDistribution(appId, event, attribute));
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @param attribute
 * @returns {function(*, *): *}
 */
export const getEventAttributeDistribution = (appId, event, attribute) => {
    return (dispatch, getState) => {
        const of = getState().eventAnalysis.current_events[event].of || CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, event, attribute});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedDistributions(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute,
                event: event
            }
        });
    };
};
/**
 *
 * @param event
 * @param attributes
 * @returns {function(*, *): *}
 */
export const resetEventAttributeDistributions = (event, attributes) => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
            payload: attributes,
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param event
 * @param of
 * @returns {function(*, *): *}
 */
export const updateEventAttributeDistributionOf = (event, of = CUSTOM_EVENT_ENUM.users) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF,
            payload: of,
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param group
 * @returns {Function}
 */
export const updateEventAnalysisUserGroup = (appId, group = USER_GROUP_ENUM.ALL_USERS) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_EVENT_ANALYSIS_USER_GROUP,
            payload: group,
            meta: {
                //
            }
        });
        //load entire data for updated group
        dispatch(reloadEventAnalysis());
        dispatch(loadEventAnalysisData(null, appId, null, true));
        dispatch(getEventsData(appId));
    };
};

/**
 *
 * @param appId
 * @param of {String} [ users | sessions ]
 * @returns {function(*, *)}
 */
export const updateAttributeDistributionOf = (appId, of = CUSTOM_EVENT_ENUM.users) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_ATTRIBUTE_DISTRIBUTION_OF,
            payload: of,
            meta: {
                //
            }
        });
        //get all distributions on change of OF
        const selectedAttributes = Object.keys(getState().eventAnalysis.distributions || {});
        selectedAttributes.forEach(attribute => {
            dispatch(getSegmentAttributeDistribution(appId, attribute));
        });
    };
};
/**
 *
 * @param event
 * @returns {function(*, *): *}
 */
export const addEvent = (event) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ADD_EVENT_ANALYSIS_CURRENT_EVENT,
            payload: event,
            meta: {

            }
        });
    };
};
/**
 *
 * @param event
 * @returns {function(*, *): *}
 */
export const deleteEvent = (event) => {
    return (dispatch, getState) => {
        return dispatch({
            type: DELETE_EVENT_ANALYSIS_CURRENT_EVENT,
            payload: event,
            meta: {

            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventCountTimeSeries = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.event;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedTimeSeries(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventUserTimeSeries = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_USER_TIME_SERIES,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedTimeSeries(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventSessionTimeSeries = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.sessions;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getEventRelatedTimeSeries(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventRetention = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_EVENT_RETENTION_PENDING,
                EVENT_ANALYSIS_EVENT_RETENTION,
                EVENT_ANALYSIS_EVENT_RETENTION_FAILED
            ],
            payload: {
                promise: getEventRetentionAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @param event
 * @returns {function(*, *): *}
 */
export const getEventRepeatedRetention = (appId, event) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, event});
        return dispatch({
            types: [
                EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING,
                EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION,
                EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED
            ],
            payload: {
                promise: getEventRepeatedRetentionAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getAllUsersCount = (appId) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of});
        return dispatch({
            types: [
                ALL_USERS_COUNT_PENDING,
                ALL_USERS_COUNT,
                ALL_USERS_COUNT_FAILED
            ],
            payload:{
                promise: getAllUsersCountAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
              //None Yet!
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getAllSessionsCount = (appId) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.sessions;
        const filters = makeFilters(getState(), {of});
        return dispatch({
            types: [
                ALL_SESSIONS_COUNT_PENDING,
                ALL_SESSIONS_COUNT,
                ALL_SESSIONS_COUNT_FAILED
            ],
            payload: {
                promise: getAllSessionsCountAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getSegmentAttributes = (appId) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_ATTRIBUTES,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    const { user = [], session = [] } = attributes;
                    if(Array.isArray(user)) {
                        /*user.forEach(attribute => {
                            dispatch(getSegmentAttributeDistribution(appId, attribute));
                        });*/
                    }else{
                        logger.error("No user attributes fetched..!")
                    }
                    if(Array.isArray(session)) {
                        /*session.forEach(attribute => {
                            dispatch(getSegmentAttributeDistribution(appId, attribute));
                        });*/
                    }else{
                        logger.error("No session attributes fetched..!")
                    }
                }
            }
        });
    };
};
/**
 *
 * @param appId
 * @param attribute
 * @returns {function(*, *): *}
 */
export const getSegmentAttributeDistribution = (appId, attribute) => {
    return (dispatch, getState) => {
        const of = getState().eventAnalysis.of || CUSTOM_EVENT_ENUM.users;
        const filters = makeFilters(getState(), {of, attribute});
        return dispatch({
            types: [
                EVENT_ANALYSIS_PENDING,
                EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
                EVENT_ANALYSIS_FAILED
            ],
            payload: {
                promise: getAttributeDistributionAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute
            }
        });
    };
};

export const getSegmentRetention = (appId) => {
    return (dispatch, getState) => {
        const filters = makeFilters(getState());
        return dispatch({
            types: [
                EVENT_ANALYSIS_RETENTION_PENDING,
                EVENT_ANALYSIS_RETENTION,
                EVENT_ANALYSIS_RETENTION_FAILED
            ],
            payload: {
                promise: getRetentionAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};
/**
 *
 * @param appId
 * @param events
 * @param all
 * @returns {Function}
 */
export const updateSegmentEvents = (appId, events, all = false) => {
    return (dispatch, getState) => {
        //dispatch(updateEventAnalysisGlobalFilters({}));
        let requiredEvents = [];
        const existingEvents = Object.keys(getState().eventAnalysis.current_events);
        if(all) {
            existingEvents.forEach(event => { //cleaning all existing
                dispatch(deleteEvent(event));
            });
            requiredEvents = events || existingEvents;
        }else{
            requiredEvents = events.filter(a => existingEvents.indexOf(a) === -1);
            if(requiredEvents.length === 0){
                let removableEvents = existingEvents.filter(a => events.indexOf(a) === -1);
                removableEvents.forEach(event => {
                    dispatch(deleteEvent(event));
                });
            }
        }
        requiredEvents.forEach(event => {
            dispatch(getEventData(appId, event, all));
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getTopEvents = (appId) => {
    return (dispatch, getState) => {
        const of = CUSTOM_EVENT_ENUM.event;
        const filters = makeFilters(getState(), {of, attribute: "apx_event_name" });
        return dispatch({
            types: [
                GET_TOP_EVENTS_PENDING,
                GET_TOP_EVENTS,
                GET_TOP_EVENTS_FAILED
            ],
            payload: {
                promise: getTopEventsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};
/**
 *
 * @param appId
 * @param events
 * @param groupId
 * @param of
 * @returns {Function}
 */
export const getEventsData = (appId, events, groupId, of = CUSTOM_EVENT_ENUM.users) => {
    return (dispatch, getState) => {
        const { current_events = {} } = getState().eventAnalysis;
        let currentEvents = events || Object.keys(current_events);
        if(groupId) //FIXME: handle this for multiple groups
            dispatch(setDefaultFilters(getGroupTypeFromList(getState().app.userGroupIds, groupId)));
        else
            dispatch(setDefaultFilters());
        if(currentEvents.length === 0){
            dispatch(getEvents(appId));
        }else{
            currentEvents.forEach(event => {
                dispatch(getEventData(appId, event, true, current_events[event].of || of));
            });
        }
    };
};
/**
 *
 * @param appId
 * @param event
 * @param withFilters
 * @param of
 * @returns {Function}
 */
export const getEventData = (appId, event, withFilters = false, of = CUSTOM_EVENT_ENUM.users) => {
    return (dispatch, getState) => {
        if(!withFilters){
            dispatch(addEvent(event));
            dispatch(getEventAttributes(appId, event));
            //dispatch(resetEventAttributeDistributions(event)); //resetting current attributes
        }else{
            const selectedAttributes = Object.keys(getState().eventAnalysis.current_events[event].distributions);
            dispatch(getEventAttributeDistributions(appId, event, selectedAttributes, true, of));
        }
        dispatch(getEventCount(appId, event));
        //FIXME: THIS AT THE API LEVEL : Temporary despicable 'HACK'
        dispatch(getEventKPIs(appId, event));
        dispatch(getEventUserCount(appId, event));
        dispatch(getEventSessionCount(appId, event));
        dispatch(getEventCountTimeSeries(appId, event));
        dispatch(getEventUserTimeSeries(appId, event));
        dispatch(getEventSessionTimeSeries(appId, event));
        //FIXME: may not be correct way to find which retention is currently active
        /*const { retention = {}, repeated_retention = {} } = getState().eventAnalysis.current_events[event] || {};
        if(withFilters && Object.keys(retention).length > 0){
            dispatch(getEventRetention(appId, event));
        }
        if(withFilters && Object.keys(repeated_retention).length > 0){
            dispatch(getEventRepeatedRetention(appId, event));
        }*/
    };
};


/**
 * Loads all Custom overview data
 * @param userGroupIds
 * @param appId
 * @param groupId
 * @param isThroughFilters
 * @returns {function(*, *)}
 */
export function loadEventAnalysisData(userGroupIds, appId, groupId, isThroughFilters = false){
    return (dispatch, getState) => {
        let currentGroupId = groupId;
        if(!currentGroupId){
            currentGroupId = getGroupIdFromList(getState().app.userGroupIds, USER_GROUP_ENUM.ALL_USERS);
        }
        //dispatch(getTopEvents(appId));
        //dispatch(getActiveUsersCount(appId));
        //dispatch(getNewInstallUsersCount(appId));
        dispatch(updateUserGroupId(currentGroupId));
        dispatch(getAllUsersCount(appId));
        //dispatch(getUninstallUsersCount(appId));
        dispatch(getAllSessionsCount(appId));
        if(isThroughFilters){
            dispatch(getTopEvents(appId)); //FIXME: not sure if it's not collapsed
            const selectedAttributes = Object.keys(getState().eventAnalysis.distributions || {});
            selectedAttributes.forEach(attribute => {
                dispatch(getSegmentAttributeDistribution(appId, attribute));
            });
        }else{
            dispatch(getSegmentAttributes(appId));
        }
        dispatch(getOverviewUserTimeSeries(DAU_TIME_SERIES, currentGroupId, appId));
        dispatch(getOverviewSessionTimeSeries(SESSION_TIME_SERIES, currentGroupId, appId));
        //dispatch(getSegmentRetention(appId));
        //Calling 360 time-spent timeseries
        dispatch(getTimespentTimeSeries(appId, CUSTOM_EVENT_ENUM.sessions))
        dispatch(getTimespentTimeSeries(appId, CUSTOM_EVENT_ENUM.users))

        // Get Normalized Timeseries
        dispatch(getTimespentTimeSeries(appId, CUSTOM_EVENT_ENUM.sessions, true))
        dispatch(getTimespentTimeSeries(appId, CUSTOM_EVENT_ENUM.users, true))
    };
}

/**
 *
 * @returns {function(*, *): *}
 */
export const resetSegment = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_EVENT_ANALYSIS,
            payload: null,
            meta: {

            }
        });
    };
};

/**
 *
 * @param filters
 * @returns {function(*, *)}
 */
export function updateEventAnalysisGlobalFilters(filters) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS,
            payload: filters,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param event
 * @param filters
 * @returns {function(*, *): *}
 */
export const updateEventAnalysisAttributeFilters = (event, filters) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS,
            payload: filters,
            meta: {
                event
            }
        });
    };
};

/**
 *
 * @param days
 * @returns {function(*, *): *}
 */
export const updateGroupDays = (days = {}) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_EVENT_ANALYSIS_GROUP_DAYS,
            payload: days,
            meta: {
                //
            }
        });
    };
};

export const reloadEventAnalysis = () => {
    return (dispatch, getState) => {
        logger.debug("Reloading Event Analysis");
        return dispatch({
            type: CLEAN_EVENT_ANALYSIS,
            payload: null,
            meta: {
                //
            }
        });
    };
};

export const getTimespentTimeSeries = (appId, of = CUSTOM_EVENT_ENUM.users, normalized = false) => {
    return (dispatch, getState) => {
        const filters = makeFilters(getState(), {of});
        return dispatch({
            types: [
                TIME_SPENT_PENDING,
                TIME_SPENT,
                TIME_SPENT_FAILED
            ],
            payload: {
                promise: getTimeSpentAPI(getState().auth, appId, filters, normalized)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                of,
                normalized,
            }
        });
    };
};
