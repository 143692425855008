/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:39 AM
 */

import moment from "moment";
import {
    ADD_NEXT_STEP_TO_CAMPAIGN,
    DELETE_MESSAGE2,
    DELETE_MESSAGE2_FAILED,
    DELETE_MESSAGE2_PENDING,
    DELETE_STEP_FROM_CAMPAIGN,
    GET_CLIENT_EVENTS,
    GET_MESSAGES,
    GET_MESSAGES_FAILED,
    GET_MESSAGES_PENDING,
    PAUSE_MESSAGE2,
    PAUSE_MESSAGE2_FAILED,
    PAUSE_MESSAGE2_PENDING,
    PUBLISH_MESSAGE2,
    PUBLISH_MESSAGE2_FAILED,
    PUBLISH_MESSAGE2_PENDING,
    RESET_NEW_MESSAGE_STATE,
    RESET_STATUS_MESSAGE,
    RESUME_MESSAGE2,
    RESUME_MESSAGE2_FAILED,
    RESUME_MESSAGE2_PENDING,
    SAVE_MESSAGE2,
    SAVE_MESSAGE2_FAILED,
    SAVE_MESSAGE2_PENDING, SET_ACTING_ID,
    SET_NEW_MESSAGE_FROM_EDIT,
    UPDATE_CAMPAIGN_STEP,
    UPDATE_CURRENT_STEP_SELECT,
    UPDATE_IN_FLOW,
    UPDATE_IN_NEW_UI
} from "./actionTypes";
import generateShortID from "./utils";

const __NEW_MESSAGE_INITIAL_STATE = {
    audience: {},
    conditions: [],
    enabled: false,
    frequency: {},
    meta: {},
    mutation_id: null,
    overall_cfg: {},
    published: false,
    scheduling: {},
    sequence_enabled: false,
    terminate_info: {},
    testing: {},
    time_limits_in_day: false,
    at_specific_time: false,
    ui: {},
    validity: {}
};

const getDateTime = (offset, unit) => {
    let NOW = moment();
    if (offset && unit) {
        NOW = NOW.add(offset, unit);
    }
    return {
        date: NOW.format("YYYY-MM-DD"),
        time: NOW.format("HH:mm")
    }
};

const NEW_MESSAGE_INITIAL_STATE = {
    audience: {
        "audience_type": "ALL",
        "attributes": {
            "user": [],
            "session": []
        },
        "limited": {},
        "segment_id": "",
        end_time: moment().toISOString(),
        start_time: moment().subtract(7, "d").toISOString()
    },
    conditions: [],
    enabled: false,
    frequency: {
        "count": 1,
        "time_interval": 0,
        "validity": "OVERALL"
    },
    meta: {
        name: "",
        description: "",
        config_type: "walkthrough",
        type: "WALKTHROUGH",
        platform: "",
        v2: true,
    },
    mutation_id: null,
    overall_cfg: {
        "ret_day": {},
        "events": [],
        "session": {},
        "toggleRetDay": false,
        "toggleSession": false
    },
    published: false,
    scheduling: {
        "schedule_date": getDateTime().date,
        "schedule_time": getDateTime(1, "h").time,
        "schedule": "LATER",
        "isRecurEnabled": false,
        "rollSegment": false,
        "rollInterval": 1,
        "recur": "DAY",
        "step": 1
    },
    sequence_enabled: false,
    terminate_info: {
        "auto_dismiss": false,
        "duration": 5000
    },
    testing: {
        "enabled": true,
        "devices": []
    },
    time_limits: {
        "start_time": "10:00",
        "end_time": "20:30"
    },
    time_limits_in_day: false,
    at_specific_time: false,
    ui: {},
    uis: [],
    validity: {
        "start_date": moment().toISOString(),
        "end_date": moment().add(5, 'days').toISOString(),
        // "run_forever": false
    }
};

const INITIAL_GET_MESSAGES_STATES = {
    messages_pending: false,
    messages_failed: false
};

const INITIAL_DELETE_MESSAGE_STATE = {
    message_delete_pending: false,
    message_delete_failed: false
};

const INITIAL_SAVE_MESSAGE_STATE = {
    message_save_pending: false,
    message_save_failed: false
};

const INITIAL_PAUSE_MESSAGE_STATE = {
    message_pause_pending: false,
    message_pause_failed: false
};

const INITIAL_RESUME_MESSAGE_STATE = {
    message_resume_pending: false,
    message_resume_failed: false
};

const INITIAL_PUBLISH_MESSAGE_STATE = {
    message_publish_pending: false,
    message_publish_failed: false
};

export const INITIAL_MESSAGES2_STATE = {
    messages_list: [],
    basicMetrics: {},
    client_events_list: [],
    progress_state: {
        ...INITIAL_GET_MESSAGES_STATES,
        ...INITIAL_DELETE_MESSAGE_STATE,
        ...INITIAL_SAVE_MESSAGE_STATE,
        ...INITIAL_PAUSE_MESSAGE_STATE,
        ...INITIAL_PUBLISH_MESSAGE_STATE,
        ...INITIAL_RESUME_MESSAGE_STATE,
        resetMessageState: false,
        statusMessage: "",
        actingID: null
    },
    utils: {
        context_query: {}
    },
    new_ui: {},
    new_message: {...NEW_MESSAGE_INITIAL_STATE}
};

function messagesReducer2(state = INITIAL_MESSAGES2_STATE, action = {}) {
    let {new_message: {uis}} = state;
    switch (action.type) {
        case GET_MESSAGES :
            let messagesObj = action.payload;
            return {
                ...state,
                messages_list: messagesObj.messages || [],
                basicMetrics: messagesObj.metrics || {},
                progress_state: {
                    ...state.progress_state,
                    messages_pending: false
                }
            };
        case GET_MESSAGES_FAILED:
            return {
                ...state,
                messages_failed: true
            };
        case GET_MESSAGES_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    messages_pending: true
                }
            };

        case GET_CLIENT_EVENTS:
            return {
                ...state,
                client_events_list: action.payload
            };
        case SET_NEW_MESSAGE_FROM_EDIT:
            return {
                ...state,
                new_message: {
                    ...action.payload
                }
            };

        case UPDATE_IN_FLOW:
            // window.localStorage.setItem("apx_messages2_cache", JSON.stringify({...action.payload}));
            return {
                ...state,
                new_message: {
                    ...action.payload
                }
            };
        case UPDATE_IN_NEW_UI:
            const {meta: {index}, payload} = action;
            uis[index] = payload;
            // window.localStorage.setItem("apx_messages2_cache", JSON.stringify({...state.new_message, uis: uis}));
            return {
                ...state,
                new_message: {
                    ...state.new_message,
                    uis: uis
                },
                new_ui: {
                    ...payload
                }
            };
        case DELETE_STEP_FROM_CAMPAIGN:
            const {stepIndex} = action.payload;
            return {
                ...state,
                new_ui: {},
                new_message: {
                    ...state.new_message,
                    uis: [...state.new_message.uis.slice(0, stepIndex), ...state.new_message.uis.slice(stepIndex + 1, uis.length)]
                }
            };
        case ADD_NEXT_STEP_TO_CAMPAIGN:
            const thisId = generateShortID();
            return {
                ...state,
                new_ui: {...action.payload, _id: thisId},
                new_message: {
                    ...state.new_message,
                    uis: [...state.new_message.uis, {...action.payload, _id: thisId}]
                }
            };
        case UPDATE_CAMPAIGN_STEP:
            return {
                ...state,
            };
        case UPDATE_CURRENT_STEP_SELECT:
            return {
                ...state,
                new_ui: {
                    ...action.payload
                }
            };

        case RESET_NEW_MESSAGE_STATE:
            if (action.meta.hasOwnProperty("nullifyLocalStorage") && action.meta.nullifyLocalStorage)
                window.localStorage.setItem("apx_messages2_cache-" + action.meta.appId, JSON.stringify(INITIAL_MESSAGES2_STATE.new_message));
            return {
                ...INITIAL_MESSAGES2_STATE,
                progress_state: {
                    ...INITIAL_MESSAGES2_STATE.progress_state,
                    resetMessageState: true,
                }
            };

        case DELETE_MESSAGE2:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    ...INITIAL_DELETE_MESSAGE_STATE,
                    statusMessage: "Deleted Message Successfully",
                    actingID: null
                }
            };
        case DELETE_MESSAGE2_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: false,
                    message_delete_failed: true,
                    actingID: null,
                    statusMessage: "Deleting Message Failed",
                }
            };
        case DELETE_MESSAGE2_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: true,
                    message_delete_failed: false,
                    actingID: action.meta.messageId
                }
            };

        case PUBLISH_MESSAGE2:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    ...INITIAL_PUBLISH_MESSAGE_STATE,
                    statusMessage: "Published Message Successfully",
                    actingID: null
                }
            };
        case PUBLISH_MESSAGE2_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: false,
                    message_delete_failed: true,
                    actingID: null,
                    statusMessage: "Publishing Message Failed",
                }
            };
        case PUBLISH_MESSAGE2_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: true,
                    message_delete_failed: false,
                    actingID: action.meta.messageId
                }
            };

        case PAUSE_MESSAGE2:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    ...INITIAL_PAUSE_MESSAGE_STATE,
                    statusMessage: "Paused Message Successfully",
                    actingID: null
                }
            };
        case PAUSE_MESSAGE2_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_pause_pending: false,
                    message_pause_failed: true,
                    actingID: action.meta.messageId,
                    statusMessage: "Pausing Message Failed",
                }
            };
        case PAUSE_MESSAGE2_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_pause_pending: true,
                    message_pause_failed: false,
                    actingID: action.meta.messageId
                }
            };

        case RESUME_MESSAGE2:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    ...INITIAL_PUBLISH_MESSAGE_STATE,
                    statusMessage: "Resumed Message Successfully",
                    actingID: null
                }
            };
        case RESUME_MESSAGE2_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: false,
                    message_delete_failed: true,
                    actingID: action.meta.messageId,
                    statusMessage: "Resuming Message Failed",
                }
            };
        case RESUME_MESSAGE2_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_delete_pending: true,
                    message_delete_failed: false,
                    actingID: action.meta.messageId
                }
            };

        case SAVE_MESSAGE2:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    ...INITIAL_SAVE_MESSAGE_STATE,
                    statusMessage: "Saved Message Successfully",
                    actingID: null
                }
            };
        case SAVE_MESSAGE2_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_save_failed: false,
                    message_save_pending: true,
                    actingID: action.meta.messageId,
                    statusMessage: "Saving Message Failed",
                }
            };
        case SAVE_MESSAGE2_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    message_save_pending: true,
                    message_save_failed: false,
                    actingID: action.meta.messageId
                }
            };

        case SET_ACTING_ID: {
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    actingID: action.payload
                }
            }
        }

        case RESET_STATUS_MESSAGE:
            return {
                ...state,
                progress_state: {
                    ...INITIAL_MESSAGES2_STATE.progress_state
                }
            };
        default:
            return state;
    }
}

export default messagesReducer2;