/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import Box from '../../../../../../components/reusable/Box';
import {
    getGroupFromList, toTimeSpent
} from '../../../../../../utils';
import { USER_GROUPS, COUNT_TYPE_ENUM} from '../../../../../../constants';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DimensionSelector from "./components/DimensionSelector";
import BuildIcon from '@material-ui/icons/Build';
import CheckboxGroup from "./../../../../../../components/reusable/MaterialUi/CheckboxGroup";
import Explorer from "./Explorer";
import FlatTable from "./FlatTable";
import Dialog from '../../../../../../components/reusable/MaterialUi/Dialog';
import IconButton from '@material-ui/core/ButtonBase';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Filters from "./components/Filters";
import AddToDashboard from "../CustomDashboards/AddToDashboard";
import {CUSTOM_REPORTS_TABLE} from "../SelfComponents";

/*
export class Filters extends React.Component{
    render(){
        const { params: { appId }, customOverview: { group }, updateUserGroup} = this.props;
        return(
            <Grid container>
                <Grid item xs>
                    <Switch
                        data={USER_GROUPS}
                        value={group}
                        handleChange={group => updateUserGroup(appId, group)}
                        containerStyles={{marginTop: 0}}
                    />
                </Grid>
            </Grid>
        )
    }
}
*/

const { users, sessions, impressions } = COUNT_TYPE_ENUM;
/**
 * Supported Metrics
 * @type {{value: string, label: *}[]}
 */
const METRICS = [ //FIXME: Might change
    {
        label: "Total Events",
        value: impressions
    },
    {
        label: "Users",
        value: users
    },
    {
        label: "Sessions",
        value: sessions
    }
];


const EXPLORER = "explorer";
const FLAT_TABLE = "flat-table";

const DEFAULT_TITLE = "New Report";

export class CustomReportHolder extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            goClicked: false,
            active: EXPLORER,
            metrics: [],
            dimensions: [],
            queryChanged: false,
            deleteTriggered: false,
            saveTriggered: false,
            reportName: "",
            title: DEFAULT_TITLE,
            filters: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.goClicked){
            this.setState({goClicked: false, queryChanged: false});
        }
    }

    getCustomReportResponsesLimit = () => {
        const { appState: {app: {features = []}} } = this.props;
        let key = "APX_CR_LIMIT_";
        let limit = 1000;
        features.forEach(feature => {
            if(feature.indexOf(key) !== -1) {
                limit = feature.slice(key.length)
            }
        });
        return Number(limit);
    }

    readyToGo = () => {
        const { customReport: { metrics, dimensions} } = this.props;
        return metrics.length > 0 && dimensions.length > 0;
    };

    render(){
        const {
            params: { appId },
            customReport: {
                report: {
                    name,
                    _id = null
                } = {},
                reports = []
            },
            updateMetrics, updateDimensions, updateFilters,
            deleteCustomReport, saveCustomReport, updateCustomReport
        } = this.props;
        const {
            active, goClicked, metrics,
            dimensions, queryChanged, deleteTriggered,
            saveTriggered, reportName, title, filters
        } = this.state;
        const readyToGo = this.readyToGo();
        const isSavedReport = !!_id;
        return(
            <Box
                title={title}
                icon={title === DEFAULT_TITLE ? <BuildIcon /> : <AssignmentIcon/>}
                defaultExpanded
                headerWithBorder
                collapsible
                controls={
                    <MultiSelect
                        options={reports.map(o => ({label: o.name, value: o._id}))}
                        value={_id}
                        handleChange={reportId => {
                            updateCustomReport(reportId);
                            const { metrics = [], dimensions = [], name = DEFAULT_TITLE, filters = [] } = getGroupFromList(reports, reportId) || {};
                            this.setState({metrics, dimensions, title: name, filters});
                            updateMetrics(metrics);
                            updateDimensions(dimensions);
                            updateFilters(filters);
                        }}
                        placeholder="Select Saved Report"
                        single
                        clearable
                        style={{maxWidth: 460}}
                    />
                }
                footer={
                    readyToGo &&
                    <Grid container>
                        <Grid item xs>
                            <Typography variant={"subtitle1"}>
                                Showing top <b>{this.getCustomReportResponsesLimit()}</b> responses.
                            </Typography>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={active}
                                    onChange={(e, active) => {
                                        this.setState({active});
                                    }}
                                    variant="fullWidth"
                                    centered
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                >
                                    <Tab fullWidth textColor="primary" disabled={!readyToGo} value={EXPLORER} label="Explorer" />
                                    <Tab fullWidth textColor="primary" disabled={!readyToGo} value={FLAT_TABLE} label="Flat Table" />
                                </Tabs>
                            </AppBar>
                            {
                                active === EXPLORER && <Explorer {...this.props} needUpdate={!queryChanged} />
                            }
                            {
                                active === FLAT_TABLE && <FlatTable {...this.props} needUpdate={!queryChanged} />
                            }
                        </Grid>
                    </Grid>
                }
            >
                <div style={{width: '100%', padding: 10}}>
                    <Typography variant="subtitle1">Metrics</Typography>
                    <CheckboxGroup
                        options={METRICS} value={metrics}
                        handleChange={metrics => {
                            this.setState({queryChanged: true, metrics});
                        }}
                        labelRenderer = {(o) => o.label}
                    />
                    <Divider />
                    <Typography style={{marginTop: 10}} variant="subtitle1">Dimensions</Typography>
                    <DimensionSelector {...this.props} dimensions={dimensions} handleChange={dimensions => {
                        this.setState({queryChanged: true, dimensions});
                    }}/>
                    <Divider style={{marginTop: 10}}/>
                    <Typography style={{marginTop: 10}} variant="subtitle1">Filters</Typography>
                    <Filters
                        {...this.props}
                        filters={filters}
                        handleUpdate={filters => {
                            this.setState({queryChanged: true, filters});
                        }}
                    />
                    <Grid container justify="flex-end">
                        {
                            isSavedReport && <Grid item xs={2} md={1}>
                                <div style={{display: 'flex'}}>
                                    <Tooltip id="tooltip-fab-delete" title="Delete this report" placement="bottom">
                                        <IconButton color="inherit" onClick={() => this.setState({deleteTriggered: true})}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                    <Dialog
                                        status={deleteTriggered}
                                        handleConfirm={() => {
                                            deleteCustomReport(appId, _id);
                                            const metrics = [], dimensions = [], filters = [];
                                            this.setState({deleteTriggered: false, metrics, dimensions, title: DEFAULT_TITLE});
                                            updateMetrics(metrics);
                                            updateDimensions(dimensions);
                                            updateFilters(filters);
                                        }}
                                        title="Delete Confirmation"
                                        handleClose={() => this.setState({deleteTriggered: false})}
                                        confirmLabel="Confirm"
                                        allowCancelLabel="Cancel"
                                    >
                                        <p>Are you sure want to <strong>delete</strong> report <strong>{ name }</strong></p>
                                    </Dialog>
                                </div>
                            </Grid>
                        }
                        {
                            readyToGo && !isSavedReport && <Grid item xs={2} md={1}>
                                <Tooltip id="tooltip-fab-save" title="Save this report" placement="bottom">
                                    <IconButton color="inherit" onClick={() => {
                                        this.setState({saveTriggered: true});
                                    }}>
                                        <SaveIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <Dialog
                                    status={saveTriggered}
                                    handleConfirm={() => {
                                        if(reportName && reportName.trim().length > 0){
                                            saveCustomReport(appId, reportName);
                                        }
                                        this.setState({saveTriggered: false});
                                    }}
                                    title="Saving Report"
                                    handleClose={() => this.setState({saveTriggered: false})}
                                    confirmLabel="Save"
                                    allowCancelLabel="Cancel"
                                >
                                    <TextField
                                        value={reportName}
                                        fullWidth
                                        onChange={e => {
                                            this.setState({reportName: e.target.value})
                                        }}
                                        label="Report Name"
                                        placeholder="Report Name"
                                        type="text"
                                        required
                                    />
                                </Dialog>
                            </Grid>
                        }
                        {
                            readyToGo && isSavedReport && queryChanged && <Grid item xs={2} md={1}>
                                <Tooltip id="tooltip-fab-save" title="Save this as new report" placement="bottom">
                                    <IconButton color="inherit" onClick={() => {
                                        this.setState({saveTriggered: true});
                                    }}>
                                        <SaveIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <Dialog
                                    status={saveTriggered}
                                    handleConfirm={() => {
                                        if(reportName && reportName.trim().length > 0){
                                            saveCustomReport(appId, reportName);
                                        }
                                        this.setState({saveTriggered: false});
                                    }}
                                    title="Saving Report"
                                    handleClose={() => this.setState({saveTriggered: false})}
                                    confirmLabel="Save"
                                    allowCancelLabel="Cancel"
                                >
                                    <TextField
                                        value={reportName}
                                        fullWidth
                                        onChange={e => {
                                            this.setState({reportName: e.target.value})
                                        }}
                                        label="Report Name"
                                        placeholder="Report Name"
                                        type="text"
                                        required
                                    />
                                </Dialog>
                            </Grid>
                        }
                        <Grid item xs={3} md={1}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={ !queryChanged || !(metrics.length > 0 && dimensions.length > 0)}
                                onClick={e => {
                                    updateMetrics(metrics);
                                    updateDimensions(dimensions);
                                    updateFilters(filters);
                                    this.setState({queryChanged: false, goClicked: true});
                                }}
                            >Update</Button>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        )

    }

}