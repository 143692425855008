/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const APXOR_SDK_CATEGORY_CONSTANTS = {
    RETENTION: "Retention",
    FUNNELS: "Funnels",
    BEHAVIOUR_CORRELATION_FRAMEWORK: "Behaviour Correlation Framework",
    COHORT: "Cohort",
    VERSION: "Version",
    EVENT: "Event Analysis",
    DASHBOARD: "Dashboard",
    NAVIGATION: "Navigation",
    UNINSTALLS: "Uninstalls",
    USER_SEGMENTS: "User Segments",
    USER_EXPLORER: "User Explorer",
    CAMPAIGNS: "Campaigns"
};

export const APXOR_ROLES = {
    APX_ADMIN: "APX_ADMIN",
    REGULAR_USER: "USER"
};

export const USER_GROUP_ENUM = {
    "INSTALL_USERS": "install_users",
    "INACTIVE_USERS": "inactive_users",
    "UNHAPPY_USERS": "unhappy_users",
    "UNINSTALL_USERS": "uninstall_users",
    "ACTIVE_USERS": "active_users",
    "HAPPY_USERS": "happy_users",
    "ALL_USERS": "all_users",
    "REINSTALL_USERS": "reinstall_users",
    "D0_UNINSTALL_USERS": "d0_uninstall_users",
    "D1_UNINSTALL_USERS": "d1_uninstall_users",
    "D2TO7_UNINSTALL_USERS": "d2to7_uninstall_users",
    "D8T14_UNINSTALL_USERS": "d8to14_uninstall_users",
    "D15T30_UNINSTALL_USERS": "d15to30_uninstall_users",
    "CUSTOM_GROUP_USERS": "custom_group_users",
    "CUSTOM_GROUP_USERS_WITH_EVENTS": "custom_group_users_with_events",
    "RETAINED_USERS": "retained_users",
    "RETURNING_USERS": "returning_users"
};

/**
 * Need to move them form here to Overview Page
 * @type {string}
 */
export const ACTIVE_USERS = "active_users";
export const INACTIVE_USERS = "inactive_users";
export const INSTALL_USERS = "install_users";
export const UNINSTALL_USERS = "uninstall_users";
export const UNHAPPY_USERS = "unhappy_users";
export const HAPPY_USERS = "happy_users";
export const ALL_USERS = "all_users";
export const REINSTALL_USERS = "reinstall_users";
export const D0_UNINSTALL_USERS = "d0_uninstall_users";
export const D1_UNINSTALL_USERS = "d1_uninstall_users";
export const D2TO7_UNINSTALL_USERS =  "d2to7_uninstall_users";
export const D8T14_UNINSTALL_USERS =  "d8to14_uninstall_users";
export const D15T30_UNINSTALL_USERS = "d15to30_uninstall_users";
export const CUSTOM_GROUP_USERS = "custom_group_users";
export const CUSTOM_GROUP_USERS_WITH_EVENTS = "custom_group_users_with_events";
export const RETAINED_USERS = "retained_users";

/**
 *
 * @type {{USABILITY: string, STABILITY: string, PERFORMANCE: string}}
 */
export const USP_APIS = {
    USABILITY: "/SUP/usability",
    STABILITY: "/SUP/stability",
    PERFORMANCE: "/SUP/performance",
    FUNCTIONALITY: "/SUP/functionality"
};

/**
 *
 * @type {{USABILITY: string, STABILITY: string, PERFORMANCE: string, FUNCTIONALITY: string}}
 */
export const USP_ENUM = {
    USABILITY: "usability",
    STABILITY: "stability",
    PERFORMANCE: "performance",
    FUNCTIONALITY: "functionality"
};

/**
 *
 * @type {{ALL_SESSIONS: string, FATAL_SESSIONS: string, UNINSTALL_SESSIONS: string, FIRST_SESSIONS: string, LAST_SESSIONS: string}}
 */
export const SESSION_GROUP_ENUM = {
    ALL_SESSIONS: "all_sessions",
    FATAL_SESSIONS: "fatal_sessions",
    UNINSTALL_SESSIONS: "uninstall_sessions",
    FIRST_SESSIONS: "first_sessions",
    LAST_SESSIONS: "last_sessions"
};

export const ALL_SESSIONS = "all_sessions";
export const FATAL_SESSIONS = "fatal_sessions";
export const UNINSTALL_SESSIONS = "uninstall_sessions";
export const FIRST_SESSIONS = "first_sessions";
export const LAST_SESSIONS = "last_sessions";


/**
 *
 * @type {{ASC: number, DESC: number}}
 */
export const SortUtils = {
    ASC : +1,
    DESC: -1
};

/**
 *
 * @type {{acquisition_sources: Array, custom_keys: null, app_versions: Array, devices: Array, os_versions: Array, location: Array, language: Array}}
 */
export const USER_GROUP_FILTERS = {
    app_versions: [],
    devices: [],
    os_versions: [],
    locations: [],
    languages: [],
    acquisition_sources: [],
    custom_keys: null
};

export const APP_PLATFORMS = {
    android: "android",
    ios: "ios",
    web: "web",
    omni: "omni"
};

export const LOGICAL_OPERATORS = {
    GT: "GT",
    GTE: "GTE",
    LT: "LT",
    LTE: "LTE",
    EQ: "EQ",
    NEQ: "NEQ"
};

export const CONTAINS_OPERATORS = {
    CONTAINS : "CONTAINS",
    NOTCONTAINS: "NOTCONTAINS"
}

export const CONDTIONAL_OPERATORS = {
    AND: "AND",
    OR: "OR",
    //NOT: "NOT"
};

export const MODES_ENUM = {
    PRODUCTION: "Production",
    NON_PRODUCTION: "Non-Production"
};

export const ISSUES_ENUM = [
    { key: "spike_period", metric: "secs", visibleName: "Spike Duration" },
    { key: "spike_over_second", metric: "%", visibleName: "Spike" },
    { key: "spike_duration", metric: "secs", visibleName: "Spike Duration" },
    { key: "memory_spike", metric: "%", visibleName: "Spike" },
    { key: "current_memory_footprint", metric: "%", visibleName: "Starting Memory Footprint" },
    { key: "starting_memory_footprint", metric: "%", visibleName: "Starting Memory Footprint" },
    { key: "average_cpu_usage", metric: "%", visibleName: "Average CPU Usage" },
    { key: "response_time_duration", metric: "secs", visibleName: "Response Time Duration" }
];

export const CONDITIONS_ENUM = {
    AND: "AND",
    OR: "OR"
};

export const CONTEXT_EVENT_TYPES_ENUM = {
    "ACTIVITY": "activity_event",
    "APP": "app_event",
    "MESSAGE": "app_event",
    "CLIENT": "client_event"
};

export const EVENT_TYPE_ENUM = {
    NAVIGATION_ITEM: "NavigationItem",
    EVENT_ITEM: "EventItem",
    APP_EVENT_ITEM: "AppEventItem",
    ISSUE_ITEM: "IssueItem"
};

export const USER_STATES_ENUM = {
    HAPPY: "Happy",
    UNHAPPY: "Unhappy",
    INACTIVE: "Inactive",
    UNINSTALLED: "Uninstalled",
    ACTIVE: "Active"
};

export const NAVIGATION_REFERENCE = "nav";

/**
 * Keys for user group distributions
 * @type {{app_versions: string, devices: string, os_versions: string, location: string, language: string, acquisition_sources: string, custom_keys: string}}
 */
export const USER_GROUP_FILTERS_CONSTANTS = {
    app_versions: "app_versions",
    devices: "devices",
    os_versions: "os_versions",
    locations: "locations",
    languages: "languages",
    acquisition_sources: "acquisition_sources",
    custom_keys: "custom_keys"
};

export const HIDDEN_USER_DISTRIBUTIONS = [
    "languages", //FIXME: not sure of language
    "locations",
    "acquisition_sources"
];

export const SESSION_PARAMS = {
    sessionCount: "sessionCount",
    sessionLength: "sessionLength"
};

export const GRANULARITY_ENUM = {
    HOUR: "hour",
    SECOND: "second",
    DAY: "day",
    MONTH: "month",
    YEAR: "year"
};

export const LAUNCH_NODE_ID = "launch_node";

export const INCIDENTS_ENUM = {
    ApplicationCrash: "ApplicationCrash",
    ApplicationHangedError: "ApplicationHangedError",
    MemorySpikeError: "MemorySpikeError",
    HeavyCPUUsageErrorLevel1: "HeavyCPUUsageErrorLevel1",
    HeavyBatteryUsageErrorLevel1: "HeavyBatteryUsageErrorLevel1",
    HeavyNetworkUsageErrorLevel1: "HeavyNetworkUsageErrorLevel1",
    LowFPSError: "LowFPSError",
    SlowResponsivenessError: "SlowResponsivenessError",
    LaunchTimeError: "LaunchTimeError",
    NotInteractableError: "NotInteractableError",
    APXCustomError: "APXCustomError",
    UndoObserver: "UndoObserver",
    MemorySpikeWarning: "MemorySpikeWarning",
    MemoryLowErrorLevel1: "MemoryLowErrorLevel1",
    HeavyCPUUsageErrorLevel2: "HeavyCPUUsageErrorLevel2",
    MemoryLowErrorLevel2: "MemoryLowErrorLevel2"
};

export const OTHERS_KEY = "others";
export const UNKNOWN_KEY = "unknown";


export const CUSTOM_GROUPING_ENUM = {
    EVENTS: 'EVENTS',
    FLOW: 'FLOW'
};

export const GRAPH_DEFAULTS = {
    switchData: [
        {
            name: "7 Days",
            value: "7 Days"
        },
        {
            name: "14 Days",
            value: "14 Days"
        },
        {
            name: "30 Days",
            value: "30 Days"
        },
        {
            name: "Weeks",
            value: "Week",
        },
        {
            name: "Months",
            value: "Month"
        }
    ],
    title: "Retention analysis",
    dateDisplayFormat: "MMM DD YYYY",
    switchValue: "7 Days",
    dataDisplayFormat: "percent"
};

export const SERVER_HOSTS = {
    google: "server.apxor.com",
    azure: "serveraz.apxor.com"
};

export const SERVER_IDENTIFIERS = {
    google: "google",
    azure: "azure",
    aws: "aws"
};

export const INTERACTIONS_ENUM = {
    Fling: "Fling",
    Tap: "Tap",
    Scroll: "Scroll",
    key_tap: "key_tap",
    "Double Tap": "Double Tap",
};

export const ANIMATIONS_ENUM = [
    "none", "fade_in_fade_out",
    "left_in_right_out", "slide_up_slide_down"
];

export const HEALTH_INCIDENTS_CRASHES = "HEALTH_INCIDENTS_CRASHES";
export const HEALTH_INCIDENTS_HANGS = "HEALTH_INCIDENTS_HANGS";
export const HEALTH_INCIDENTS_MEMORY = "HEALTH_INCIDENTS_MEMORY";
export const HEALTH_INCIDENTS_CPU = "HEALTH_INCIDENTS_CPU";
export const HEALTH_INCIDENTS_BATTERY = "HEALTH_INCIDENTS_BATTERY";
export const HEALTH_INCIDENTS_DATA = "HEALTH_INCIDENTS_DATA";
export const HEALTH_INCIDENTS_FPS = "HEALTH_INCIDENTS_FPS";
export const HEALTH_INCIDENTS_RESPONSIVENESS = "HEALTH_INCIDENTS_RESPONSIVENESS";
export const HEALTH_INCIDENTS_CUSTOM_ERROR = "HEALTH_INCIDENTS_CUSTOM_ERROR";

export const HEALTH_INCIDENTS_MAP = { //FIXME: move this to global constants, since used in several places
    HEALTH_INCIDENTS_CRASHES: ["ApplicationCrash"],
    HEALTH_INCIDENTS_HANGS: ["ApplicationHangedError"],
    HEALTH_INCIDENTS_MEMORY: ["MemorySpikeError"], //TODO: more types will be there
    HEALTH_INCIDENTS_CPU: ["HeavyCPUUsageErrorLevel1"], //TODO: more types will be there
    //HEALTH_INCIDENTS_BATTERY: ["HeavyBatteryUsageErrorLevel1"], //TODO: more types will be there
    //HEALTH_INCIDENTS_DATA: ["HeavyNetworkUsageErrorLevel1"], //TODO: more types will be there
    HEALTH_INCIDENTS_FPS: ["LowFPSError"], //TODO: more types will be there
    HEALTH_INCIDENTS_RESPONSIVENESS: ["SlowResponsivenessError", "LaunchTimeError", "NotInteractableError"],
    HEALTH_INCIDENTS_CUSTOM_ERROR: ["APXCustomError"]
};

export const HEALTH_INCIDENTS_LABELS = {
    HEALTH_INCIDENTS_CRASHES: "CRASHES",
    HEALTH_INCIDENTS_HANGS: "HANGS",
    HEALTH_INCIDENTS_MEMORY: "MEMORY",
    HEALTH_INCIDENTS_CPU: "CPU",
    //HEALTH_INCIDENTS_BATTERY:
    //HEALTH_INCIDENTS_DATA:
    //HEALTH_INCIDENTS_FPS: "FPS",
    HEALTH_INCIDENTS_RESPONSIVENESS: "USABILITY",
    HEALTH_INCIDENTS_CUSTOM_ERROR: "FUNCTIONAL"
};

export const USER_GROUPS = [
    {
        name: "All Users",
        value: USER_GROUP_ENUM.ALL_USERS
    },
    {
        name: "New Installs",
        value: USER_GROUP_ENUM.INSTALL_USERS
    },
    {
        name: "Uninstalls",
        value: USER_GROUP_ENUM.UNINSTALL_USERS
    },
    {
        name: "Returning Users",
        value: USER_GROUP_ENUM.RETURNING_USERS
    }
];

export const readableUserGroupName = (group) => {
    const index = USER_GROUPS.findIndex(o => o.value === group);
    if(index > -1){
        return USER_GROUPS[index].name;
    }
    return group;
};

export const MESSAGE_EVENTS = { //FIXME: may vary, so need API
    INLINE_SHOWN: "inline_shown",
    INAPP_SHOWN: "inapp_shown",
    INLINE_DISMISSED: "inline_dismissed",
    INAPP_DISMISSED: "inapp_dismissed",
};

export const CLIENT_EVENTS = {
    BACK_BUTTON_PRESSED: "apx_hard_back_button_pressed"
};

export const COUNT_TYPE_ENUM = {
    event: "event",
    sessions: "sessions",
    users: "users",
    session_length: "session_length",
    all: "all",
    impressions: "impressions"
};

export const MAU_TIME_SERIES = "MAU_TIME_SERIES";
export const DAU_TIME_SERIES = "DAU_TIME_SERIES";
export const NEW_INSTALL_TIME_SERIES = "NEW_INSTALL_TIME_SERIES";
export const SESSION_LENGTH_TIME_SERIES = "SESSION_LENGTH_TIME_SERIES";
export const SESSION_TIME_SERIES = "SESSION_TIME_SERIES";
export const DAILY_USER_TIME_SERIES = "DAILY_USER_TIME_SERIES";

export const MESSAGE_ENUM = {
    IN_APP: 'IN_APP',
    IN_LINE: 'IN_LINE',
    WALKTHROUGH: 'WALKTHROUGH'
};


export const ART_CONFIG_TYPES = {
    PROBE: 'probe',
    SCREENSHOT: 'screenshot',
    SURVEY: 'survey',
    RTA: 'rta',
    WALKTHROUGH: 'walkthrough'
};

export const PATH_ENUM = {
    EVENT: "Event",
    SCREEN: "Screen"
};

export const DAYS_ENUM_FOR_USAGE = [0, 1, 7, 15, 30, 60, 90];

export const APXOR_APP_DOWNLOAD_LINK = window._ApxorAppDownloadLink;

export const conditionalUtterance = (operator) => {

    const { GT, GTE, EQ, NEQ, LT, LTE, R = "R" } = LOGICAL_OPERATORS;

    switch (operator) {
        case GT: return " more than ";
        case GTE: return " at least ";
        case LT: return " less than ";
        case LTE: return " at most ";
        case EQ: return " exactly ";
        case NEQ: return " not ";
        case R: return " matches with ";
        default: return ""
    }
};

export const APX_FEATURES = {
    "APX_SEGMENT_DOWNLOAD":"APX_SEGMENT_DOWNLOAD",
    "APX_DIGEST_REPORTS":"APX_DIGEST_REPORTS",
    "APX_CLIENT_EVENTS": "APX_CLIENT_EVENTS",
    "APX_NORMALIZED_TIME_SPENT": "APX_NORMALIZED_TIME_SPENT",
}