/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import Typography from "@material-ui/core/Typography";
import React from 'react';
import Box from '../../../../../../components/reusable/Box';
import InfoHelper from "../../../../../../components/reusable/InfoHelper";
import ComposedChart from '../../../../../../components/reusable/Recharts/MultiUtilityChart';
import {
    formatNumber, randomColorWithIndex, isDefined
} from '../../../../../../utils';
import {USER_GROUP_ENUM, USER_GROUPS, APXOR_SDK_CATEGORY_CONSTANTS, APX_FEATURES} from '../../../../../../constants';
import ReactGA from 'react-ga';
import {
    XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid,
    Brush, ReferenceLine, Bar, BarChart, Cell
} from 'recharts';
import HelpIcon from '@material-ui/icons/Help';
import {default as MaterialTooltip} from "@material-ui/core/Tooltip";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Group from '@material-ui/icons/Group';
import AccessTime from '@material-ui/icons/AccessTime';
import EventDistributions from '../../../../../../components/reusable/AttributeDistributions';
import {getTimespentTimeSeries} from "./actions";
import { CUSTOM_EVENT_ENUM } from './actionTypes';
import { purple, blue } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import NumberBox from '../../../../../../components/reusable/MaterialUi/NumberBox';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import Switch from "../../../../../../components/reusable/Switch";
import Checkbox from "../../../../../../components/reusable/MaterialUi/Checkbox";
import Apxor from 'apxor';

const getPercent = (total, value) => {
    if(isNaN(value) || isNaN(total) || total === 0) return "NA";
    return parseFloat(value * 100 / total).toFixed(2);
};

const SWITCH_DATA = [
    {name: "Sessions", value: CUSTOM_EVENT_ENUM.sessions},
    {name: "Users", value: CUSTOM_EVENT_ENUM.users},
    {name: "Event", value: CUSTOM_EVENT_ENUM.event}
];

const DAYS_FILTER_OPTIONS = [
    ...[...Array(8).keys()].map(i => ({label: `Day ${i}`, value: i})),
    //{label: 'Week 1 (1 - 7)', value: 7},
    {label: 'Week 2 (8 - 14)', value: 14},
    {label: 'Week 3 (15 - 21)', value: 21},
    {label: 'Week 4 (22 - 28)', value: 28},
    {label: '1st Month (1 - 30)', value: 30},
    {label: '2nd Month (31 - 60)', value: 60},
    {label: '3rd Month (61 - 90)', value: 90}
];

const DAYS_FILTER_OPTIONS_VALUES = DAYS_FILTER_OPTIONS.map(o => o.value);

class DaysFilter extends React.Component {

    state = {
        values: [],
        computedValues: [],
        applied: false
    };

    componentWillMount(){
        this.updateState(this.props);
    }

    componentWillReceiveProps(nextProps){
        const { startDay, endDay, group } = this.props;
        if(startDay !== nextProps.startDay || endDay !== nextProps.endDay || group !== nextProps.group){
            this.updateState(nextProps, true);
        }
    }

    computeValue = (start, end) => {
        if(!isDefined(start, false) || !isDefined(end, false)){
            return [];
        }
        if(end - start === 0){
            return [ start ];
        }else if(end - start < 0){
            return [];
        }
        return [...Array(end - start + 1).keys()].map(i => i + start);
    };

    handleChange = (values = []) => {
        const sortedValues = values.sort((a, b) => a - b);
        const size = sortedValues.length;
        if(size > 0){
            const min = Number(sortedValues[0]);
            const max = Number(sortedValues[size - 1]);
            let actualMin = min;
            if(min > 29){
                actualMin = min - 29;
            }else if(min > 7){
                actualMin = min - 6;
            }
            this.updateState({startDay: actualMin, endDay: max, group: this.props.group});
        }else{
            this.setState({computedValues: [], values: [], applied: false});
        }
    };

    valueRenderer = (values) => {
        const { computedValues } = this.state;
        const size = computedValues.length;
        if(size > 0){
            const min = Number(computedValues[0]);
            const max = Number(computedValues[size - 1]);
            if(min === max){
                return `Day ${min}`;
            }
            return `Day ${min} - ${max}`;
        }else{
            return "No Days Selected.";
        }
    };

    updateState = ({startDay, endDay}, applied = false) => {
        const computedValues = this.computeValue(startDay, endDay);
        this.setState({computedValues, values: computedValues.filter(i => DAYS_FILTER_OPTIONS_VALUES.includes(i)), applied});
    };

    updateToProps = () => {
        const { handleApply = () => null } = this.props;
        const { computedValues = [] } = this.state;
        const size = computedValues.length;
        if(size > 0){
            handleApply({startDay: computedValues[0], endDay: computedValues[size - 1]});
        }else{
            handleApply({});
        }
        this.setState({applied: true});
    };

    render(){
        const { values, applied } = this.state;
        const { group, disabled } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={5}>
                    <MultiSelect
                        multiple
                        options={group === USER_GROUP_ENUM.RETURNING_USERS ? DAYS_FILTER_OPTIONS.slice(1) : DAYS_FILTER_OPTIONS}
                        withCheckbox
                        handleChange={this.handleChange}
                        value={values}
                        valueRenderer={this.valueRenderer}
                        isRangeSelection
                        fullWidth
                        disabled={disabled}
                        label={values.length > 0 ? "Selected Days" : 'No Days Selected'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button style={{marginTop: 8}} disabled={disabled || applied} variant="contained" color="primary" onClick={this.updateToProps}>Apply</Button>
                </Grid>
            </Grid>

        )
    }

}

export class Filters extends React.Component{

    state = {
        enableDays: false
    };

    render(){
        const { params: { appId }, customOverview: { group, days, days: { startDay, endDay } }, updateUserGroup, updateGroupDays} = this.props;
        const { enableDays } = this.state;
        return(
            <Grid container>
                <Grid item xs>
                    <Switch
                        data={USER_GROUPS}
                        value={group}
                        handleChange={group => {
                            if(enableDays){
                                if(group === USER_GROUP_ENUM.INSTALL_USERS){
                                    updateGroupDays({startDay: 0, endDay: 0});
                                }
                                if(group === USER_GROUP_ENUM.RETURNING_USERS){
                                    if(!startDay || startDay < 1){ //FIXME: Not sure of this
                                        updateGroupDays({startDay: 1, endDay: endDay < 1 ? 1 : endDay});
                                    }
                                }
                            }
                            updateUserGroup(appId, group);
                        }}
                        containerStyles={{marginTop: 0}}
                    />
                    <Grid container spacing={16}>
                        <Grid item xs={4}>
                            {
                                group === USER_GROUP_ENUM.UNINSTALL_USERS
                                && <Typography variant={"subtitle2"} style={{margin: "4px 0"}}>
                                    <b>Note: </b>Timezone will be overridden by UTC
                                </Typography>
                            }
                            <Checkbox
                                label={ (group === USER_GROUP_ENUM.UNINSTALL_USERS ? 'Uninstall ' : '') + "Day Filter"}
                                checked={enableDays}
                                handleChange={enableDays => {
                                    if(!enableDays){
                                        if(group === USER_GROUP_ENUM.INSTALL_USERS){
                                            updateGroupDays({startDay: 0, endDay: 0});
                                        }else{
                                            updateGroupDays({});
                                        }
                                        updateUserGroup(appId, group);
                                    }
                                    this.setState({enableDays});
                                }}
                            />
                        </Grid>
                        {
                            enableDays && <Grid item xs={9}>
                                <DaysFilter
                                    {...days}
                                    handleApply={days => {
                                        updateGroupDays(days);
                                        updateUserGroup(appId, group); //FIXME: just to trigger reload page data
                                    }}
                                    group={group}
                                    disabled={group === USER_GROUP_ENUM.INSTALL_USERS}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export class UserCounts extends React.Component{
    render(){
        const { customOverview} = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={6}>
                    <Filters {...this.props}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberBox heading={formatNumber(customOverview.all_users)} subheading="Users" icon={<PeopleIcon/>}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <NumberBox heading={formatNumber(customOverview.all_sessions)} subheading="Sessions" icon={<AccessTimeIcon/>}/>
                </Grid>
            </Grid>
        )
    }
}

const metricRowStyles = theme => ({
    root: {
        WebkitJustifyContent: 'space-around',
        justifyContent: 'space-around',
        width: '100%',
        display: 'flex',
        height: 50
    },
    icon: {
        fill: blue
    }
});

const EventMetricsRowInner = (props) => {
    const { name, count, sessions, users, stats={}, classes, children } = props;
    if(children !== undefined){
        return(
            <BottomNavigation showLabels className={classes.root}>
                { children }
            </BottomNavigation>
        )
    }
    const totalSessions = stats.sessions;
    const totalUsers = stats.users;
    return (
        <BottomNavigation showLabels className={classes.root}>
            <BottomNavigationAction label={<span title="Event Count">{`${formatNumber(count)}`}</span>} style={{minWidth: 250}} icon={<span style={{color: purple}}>{`${name}`}</span>}/>
            <BottomNavigationAction label={<span><span title="Users">{formatNumber(users)}</span> <span title={`of ${totalUsers} total users`}>({getPercent(totalUsers, users)} %)</span></span>} icon={<Group className={classes.icon}/>} />
                <BottomNavigationAction label={<span><span title="Sessions">{formatNumber(sessions)}</span> <span title={`of ${totalSessions} total sessions`}>({getPercent(totalSessions, sessions)} %)</span></span>} icon={<AccessTime className={classes.icon}/>} />
        </BottomNavigation>
    );
};

const EventMetricsRow = withStyles(metricRowStyles)(EventMetricsRowInner);

export class Behavior extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            resetFilters: false
        };
    }

    handleEventSelection = (events) => {
        Apxor.logEvent("SelectEvents", {event_name:  events}, APXOR_SDK_CATEGORY_CONSTANTS.EVENT);
        ReactGA.event({
            category: 'Segment Page Events',
            action: 'Selected Events',
            label: "[ " + events.join(", ") + " ]",
            value: events.length
        });
        this.props.handleEventChange(this.props.params.appId, events);
    };

    componentWillReceiveProps(nextProps) {
        this.setState({resetFilters: false}); //FIXME:
    }

    handleAttributeChange = (event) => (attributes) => {
        Apxor.logEvent("SelectProperty", {property_name: attributes}, APXOR_SDK_CATEGORY_CONSTANTS.EVENT);
        ReactGA.event({
            category: 'Segment Page Events',
            action: 'Selected Event Properties',
            label: "Event: " + event + " >> [ " + attributes.join(", ") + " ]",
            value: attributes.length
        });
        this.props.updateEventAttributeDistributions(event, attributes);
    };

    render(){
        const {
            events = [],
            users_time_series = [],
            sessions_time_series = [],
            counts_time_series = [],
            current_events = {},
        } = this.props.customOverview;
        const {
            stats,
            appState: { attributes: { user = [], session = [] } }
        } = this.props;
        const { resetFilters } = this.state;
        const formattedEvents = events.map(event => ({label: event, value: event}));
        const currentEvents = Object.keys(current_events) || [];
        const graphDataKeys = [...currentEvents, "All"];
        return(
            <Box
                title="Events"
                icon={<EventIcon/>}
                withPadding
                controls={
                    <MultiSelect
                        placeholder="Select Events"
                        value={currentEvents}
                        options={formattedEvents}
                        handleChange={this.handleEventSelection}
                        style={{maxWidth: '60%'}}
                    />
                }
                footer={
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={4}>
                            <ComposedChart
                                height={250}
                                valueLabel="Events"
                                dataKey="key"
                                data={counts_time_series}
                                areaDataKeys={graphDataKeys}
                                withoutAggregations
                                title="Events"
                                withHeader={true}
                                color="#519674"
                                dot={false}
                                showAverage={true}
                                syncId="anyId"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ComposedChart
                                height={250}
                                valueLabel="Users"
                                dataKey="key"
                                data={users_time_series}
                                areaDataKeys={graphDataKeys}
                                withoutAggregations
                                title="Users"
                                withHeader={true}
                                color="#519674"
                                dot={false}
                                showAverage={true}
                                syncId="anyId"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ComposedChart
                                height={250}
                                valueLabel="Sessions"
                                dataKey="key"
                                data={sessions_time_series}
                                areaDataKeys={graphDataKeys}
                                withoutAggregations
                                title="Sessions"
                                withHeader={true}
                                color="#519674"
                                dot={false}
                                showAverage={true}
                                syncId="anyId"
                            />
                        </Grid>
                    </Grid>
                }
            >
                {
                    currentEvents.map(event =>{
                        const {
                            attributes = [],
                            distributions = {},
                            kpis = {},
                            of = CUSTOM_EVENT_ENUM.users
                        } = current_events[event];
                        const selectedAttributes = Object.keys(distributions);
                        const allAttributes = [...attributes, ...user, ...session]; //merging all attributes
                        return (
                            <Box
                                key={event}
                                title={<EventMetricsRow {...current_events[event]} stats={stats}/>}
                                collapsible
                                onCollapse={() => Apxor.logEvent("ExploreEventClicked", {event_name: event}, APXOR_SDK_CATEGORY_CONSTANTS.EVENT)}
                            >
                                { Object.keys(kpis).length > 0 &&
                                    <EventMetricsRow>
                                        {
                                            Object.keys(kpis).map(kpi =>
                                                <BottomNavigationAction key={kpi} label={kpi}
                                                                        icon={ <span title={kpi}>{formatNumber(kpis[kpi])}</span> }
                                                />
                                            )
                                        }
                                    </EventMetricsRow>
                                }
                                {  attributes.length > 0 &&
                                    <div style={{marginTop: 10}}>
                                        <Grid container style={{marginBottom: 10}} spacing={16} justify="flex-end">
                                            <Grid item xs={12} md>
                                                <MultiSelect
                                                    placeholder="Select Properties"
                                                    value={selectedAttributes}
                                                    options={allAttributes.map(item => ({label: item, value: item}))}
                                                    handleChange={this.handleAttributeChange(event)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Switch
                                                    data={SWITCH_DATA}
                                                    value={of}
                                                    handleChange={value => {
                                                        ReactGA.event({
                                                            category: 'Behavior Events',
                                                            action: 'Selected Event Attribute Value Selection',
                                                            label: "Event: " + event + " >> [ " + attributes.join(", ") + " ] ( " + value + " )",
                                                            value: attributes.length
                                                        });
                                                        this.props.handleEventDistributionOfChange(event)(value);
                                                    }}
                                                    containerStyles={{margin: 0}}
                                                />
                                            </Grid>
                                        </Grid>
                                        <EventDistributions
                                            {...this.props}
                                            title="Selected Properties"
                                            data={distributions}
                                            stats={stats}
                                            attributes={allAttributes}
                                            resetFilters={resetFilters}
                                            updateFilters={this.props.updateFilters(event)}
                                            messageForNoData="No Event Properties Selected"
                                            withoutAttributeSelect
                                        />
                                        {/*<EventRetentions {...this.props} {...current_events[event]}/>*/}
                                    </div>
                                }
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

}

export class TimeSpentTrends extends React.Component {

    constructor(props) {
        super(props);
        const {
            customOverview: {
                time_spent_sessions = [],
                time_spent_users = []
            }
        } = this.props;
        this.state = {
            time_spent_sessions,
            time_spent_users,
            normalize: false
        };
    }

    render() {
        const {
            customOverview: {
                time_spent_sessions = [],
                normalized_time_spent_sessions = [],
                time_spent_users = [],
                normalized_time_spent_users = [],
            },
            appState: {app: {features = []}}
        } = this.props;

        const {normalize} = this.state;
        const available_metrics = ["average", "P25", "P50", "P75", "P90"];
        const normalizedTimespentEnabled = features.includes(APX_FEATURES.APX_NORMALIZED_TIME_SPENT);
        const showNormalizedTimespent = normalize && normalizedTimespentEnabled;
        return (
            <Box
                title="360"
                icon={<EventIcon/>}
                withPadding
                footer={
                    <div>
                        {
                            normalizedTimespentEnabled
                            && <div style={{display: "flex", alignItems: "center"}}>
                                <Checkbox
                                    label={"Time Spent Percentile Distribution"}
                                    checked={normalize}
                                    style={{marginBottom: 12}}
                                    handleChange={normalize => {
                                        this.setState({normalize});
                                    }}
                                />
                                <InfoHelper>Removed outliers capped with 99 percentile standard</InfoHelper>
                            </div>
                        }
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <ComposedChart
                                    height={250}
                                    valueLabel="Time Spent Per Session (in Seconds)"
                                    dataKey="key"
                                    lineDataKeys={(showNormalizedTimespent) ? [...available_metrics] : ["sessions"]}
                                    data={(showNormalizedTimespent) ? normalized_time_spent_sessions : time_spent_sessions}
                                    withoutAggregations
                                    title="Time Spent Per Session (in Seconds)"
                                    withHeader={true}
                                    color="#519674"
                                    dot={false}
                                    syncId="anyId-sessionLength"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ComposedChart
                                    height={250}
                                    valueLabel="Time Spent Per User (in Seconds)"
                                    dataKey="key"
                                    lineDataKeys={(showNormalizedTimespent) ? [...available_metrics] : ["users"]}
                                    data={(showNormalizedTimespent) ? normalized_time_spent_users : time_spent_users}
                                    withoutAggregations
                                    title="Time Spent Per User (in Seconds)"
                                    withHeader={true}
                                    color="#519674"
                                    dot={false}
                                    syncId="anyId-sessionLength"
                                />
                            </Grid>
                        </Grid>
                    </div>
                }
            >
            </Box>
        )
    }
}

export class TopEvents extends React.Component {

    handleEventSelection = (events) => {
        ReactGA.event({
            category: 'Segment Page Events',
            action: 'Selected Events',
            label: "[ " + events.join(", ") + " ]",
            value: events.length
        });
        this.props.handleEventChange(this.props.params.appId, events);
    };

    handleClick = ({key}, index) => {
        const {
            current_events = {},
        } = this.props.customOverview;
        const currentEvents = Object.keys(current_events) || [];
        let events = currentEvents;
        const keyIndex = currentEvents.indexOf(key);
        /*if(keyIndex > -1){
            events = [...currentEvents.slice(0, keyIndex), ...currentEvents.slice(keyIndex + 1)];
        }else{
            events = [...events, key];
        }*/
        if(keyIndex < 0){
            events = [...events, key];
            this.props.handleEventChange(this.props.params.appId, events);
        }
    };

    render(){
        const {
            current_events = {},
        } = this.props.customOverview;
        const currentEvents = Object.keys(current_events) || [];
        const { data = [], getTopEvents, params: { appId } } = this.props;
        return (
            <Box
                title="Top Events"
                icon={<EventIcon/>}
                withPadding
                collapsible
                onCollapse={(notCollapsed) => {
                    if(notCollapsed){
                        Apxor.logEvent("TopEventSelect", {display_state: notCollapsed}, APXOR_SDK_CATEGORY_CONSTANTS.EVENT);
                        getTopEvents(appId);
                    }
                }}
            >
                <ResponsiveContainer height={300}>
                    <BarChart data={data}
                              margin={{top: 5, right: 20, left: 10, bottom: 5}}>
                        <XAxis dataKey={"key"} type={"category"}/>

                        <YAxis width={90} type={"number"} dataKey={"value"}
                               label={{ value:"Event count", position: "insideLeft", angle: -90,   dy: 40}}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <ReferenceLine y={0} stroke='#000'/>
                        <defs>
                            <linearGradient id="top-events" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={randomColorWithIndex(0)} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={randomColorWithIndex(0)} stopOpacity={0}/>
                            </linearGradient>
                            {/*<linearGradient id="selected-top-events" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={randomColorWithIndex(1)} stopOpacity={0.8}/>
                                <stop offset="95%" stopColor={randomColorWithIndex(1)} stopOpacity={0}/>
                            </linearGradient>*/}
                        </defs>
                        {
                            data && data.length > 10 &&
                            <Brush
                                dataKey='key'
                                height={30} stroke="#429ef4"
                                endIndex={10}
                            />
                        }
                        <Bar dataKey='value' fillOpacity={1} onClick={this.handleClick}>
                            {
                                data.map((entry, index) =>
                                    <Cell cursor="pointer" fill="url(#top-events)" key={`cell-${entry.key + index}`}/>
                                )
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        )
    }

}