/**
 * Created by Rakesh Peela
 * Date: 20-Mar-2020
 * Time: 11:08 PM
 */
import {callApi} from "../../../../../../../api";
import {makeDefaultQueryString, withQueryStrings} from "../../../../../../../utils";

const SETTINGS = "settings";
const TIMEZONE = "time-zone";

export function setTimeZoneForAppAPI(appId, auth, tz) {
    const url = makeDefaultQueryString(SETTINGS, auth, appId);
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(tz)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateAppSettingsAPI(appId, auth, appSettings) {
    const url = makeDefaultQueryString(SETTINGS, auth, appId);
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(appSettings)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateAppTZSettingsAPI(appId, auth, appSettings) {
    const debug = process.env.NODE_ENV !== "production";
    const url = makeDefaultQueryString(TIMEZONE, auth, appId) + withQueryStrings({notify: debug ? "false" : "true"});
    const config = {
        method: "PATCH",
        auth: auth,
        body: JSON.stringify(appSettings)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function getAppSettingsAPI(appId, auth) {
    const url = makeDefaultQueryString(SETTINGS, auth, appId);
    const config = {
        method: "GET",
        auth: auth,
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}