/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import moment from "moment";
import ReactGA from 'react-ga';
import {
    UPDATE_GLOBAL_MODES, UPDATE_GLOBAL_VERSIONS,
    UPDATE_GLOBAL_DATE_TIME, UPDATE_PATH_ID,
    UPDATE_USER_GROUP_ID, UPDATE_SESSION_GROUP_ID,
    UPDATE_USER_GROUP, UPDATE_COHORT_FILTER
} from './actionTypes';
import { loadUserProfile  } from '../Dashboard/Users/User/actions';
import { loadSegmentData  } from '../Dashboard/Segments/actions';
import { loadRetentionData  } from '../Dashboard/Retention/actions';
import { loadUninstallsData  } from '../Dashboard/Uninstalls/actions';
import {changeInGlobalFilters} from '../../../../../actions';
import {USER_GROUP_ENUM, SESSION_GROUP_ENUM, MODES_ENUM} from '../../../../../constants';
import {DASHBOARD_ENDPOINTS} from '../../../../../constants/EndPoints';
import {getGroupIdFromList, isDefined, normalizeFiltersDateTime} from '../../../../../utils';
import { loadMiscData } from '../Dashboard/Misc/actions';
import {loadEventAnalysisData, getEventsData} from '../Dashboard/EventAnalysis/actions';
import { getUserProfiles } from '../Dashboard/Users/UserExplorer/actions';
import { loadDropOffData } from '../Dashboard/DropOff/actions';
import { getSegmentCount } from '../Dashboard/Segments/NewSegment/actions';
import {getCohorts} from "../Dashboard/Cohorts/actions";

/**
 *
 * @param startTime
 * @param endTime
 * @returns {function(*, *)}
 */
export function updateGlobalDateTime(startTime, endTime) {
    let updatedSince = normalizeFiltersDateTime(moment(startTime).utc().startOf("day"));
    let updatedTill = normalizeFiltersDateTime(moment(endTime).utc().endOf("day"));
    // Apxor.logEvent("Global Date Time changed", {startTime, endTime}, "Filters");
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_GLOBAL_DATE_TIME,
            payload: {
                since: updatedSince,
                till: updatedTill,
            },
            meta: {
                //If Any
            }
        });
    };
}

export function updateCohortFilter(cohortObj) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_COHORT_FILTER,
            payload: cohortObj,
            meta: {
                // If any
            }
        })
    }
}

/**
 *
 * @param version
 * @returns {function(*, *)}
 */
export function updateGlobalVersions(version) {
    ReactGA.event({
        category: 'Filters',
        action: 'Global App Version changed',
        label: "Version: " + version
    });
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_GLOBAL_VERSIONS,
            payload: version,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param modes
 * @returns {function(*, *)}
 */
export function updateGlobalModes(modes = [MODES_ENUM.PRODUCTION, MODES_ENUM.NON_PRODUCTION]) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_GLOBAL_MODES,
            payload: modes,
            meta: {
                //If Any
            }
        });
    };
}
/**
 * @deprecated
 * @param filters
 * @param sourceGroupId
 * @returns {function(*, *)}
 */
export function updateUserGroup(filters, sourceGroupId) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_GROUP,
            payload: Object.assign({}, filters, {
                    source_group_id: sourceGroupId
                }),
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param groupId
 * @returns {function(*, *)}
 */
export function updateUserGroupId(groupId) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_GROUP_ID,
            payload: groupId,
            meta: {
                //If Any
            }
        });
    };
}


/**
 *
 * @param pathId
 * @returns {function(*, *)}
 */
export function updatePathId(pathId) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_PATH_ID,
            payload: pathId,
            meta: {
                //If Any
            }
        });
    };
}



/**
 *
 * @param sessionGroupId
 * @returns {function(*, *)}
 */
export function updateSessionGroupId(sessionGroupId) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SESSION_GROUP_ID,
            payload: sessionGroupId,
            meta: {
                //If Any
            }
        });
    };
}

/**
 * TODO: add more default filters if any
 * @param userGroupType
 * @param sessionGroupType
 * @param pathId
 * @returns {function(*, *)}
 */
export function setDefaultFilters(userGroupType = USER_GROUP_ENUM.ALL_USERS, sessionGroupType = SESSION_GROUP_ENUM.ALL_SESSIONS, pathId = null) {
    return (dispatch, getState) => {
        let userGroupIds = getState().app.userGroupIds || [];
        let sessionGroupIds = getState().app.sessionGroupIds || [];
        const userGroupId = getGroupIdFromList(userGroupIds, userGroupType || USER_GROUP_ENUM.ALL_USERS) || getGroupIdFromList(userGroupIds, USER_GROUP_ENUM.ALL_USERS); //if no groupId found setting default ALL_USERS
        const sessionGroupId = getGroupIdFromList(sessionGroupIds, sessionGroupType || SESSION_GROUP_ENUM.ALL_SESSIONS) || getGroupIdFromList(sessionGroupIds, SESSION_GROUP_ENUM.ALL_SESSIONS); //if no groupId found setting default ALL_USERS;
        dispatch(updateUserGroupId(userGroupId));
        dispatch(updateSessionGroupId(sessionGroupId));
        if(isDefined(pathId)){
            dispatch(updatePathId(pathId));
        }
    };
}

/**
 * Applies filters to current location
 */
export function applyGlobalFilterChanges() {
    return (dispatch, getState) => {
        dispatch(changeInGlobalFilters()); //updating meta
        const {pathname } = getState().meta.routerLocation;
        let appId = getState().app.app.app_id;
        let userGroupIds = getState().app.app.userGroupIds;
        switch(true){
            case pathname.indexOf("wip-custom-overview") > -1:
                const groupId = getState().filters.user_group.groupId;
                dispatch(loadMiscData(appId));
                //dispatch(setDefaultFilters());
                //dispatch(loadRetentionData());
                //dispatch(getEventAttributeDistributions(appId, null, null, true)); //updating existing attributes
                //dispatch(loadCustomOverviewData(userGroupIds, appId, groupId));
                //dispatch(getCustomOverviewEventsData(appId, Object.keys(getState().customOverview.current_events), groupId));
                break;
              //  break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.USER_EXPLORER) > -1 :
                dispatch(getUserProfiles(appId));
                break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.UNINSTALLS) > -1 :
                dispatch(loadUninstallsData(appId));
                break;
            case pathname.indexOf("user") > -1 :
                const userId = pathname.split("/")[4];//Takes the id from query params ::FIXME: Figure out good way to get it
                dispatch(loadUserProfile(appId, userId));
                break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.SEGMENTS + "/new") > -1 :
                dispatch(getSegmentCount(appId)); //loading segment count
                break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.SEGMENTS) > -1 :
                const segmentId = pathname.split("/")[4];
                dispatch(loadSegmentData(appId, segmentId, null, true)); //FIXME: unable to update current selected correlation
                break;
            case pathname.indexOf("retention") > -1 :
                dispatch(loadRetentionData(appId));
                break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.EVENT_ANALYSIS) > -1 :
                // dispatch(getCohorts(appId, getState().filters));
                dispatch(loadEventAnalysisData(userGroupIds, appId, groupId, true));
                dispatch(getEventsData(appId, null, groupId));
                break;
            case pathname.indexOf(DASHBOARD_ENDPOINTS.DROP_OFF) > -1 :
                dispatch(loadDropOffData(appId));
                break;
            default:
        }
    };
}