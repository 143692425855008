/**
 * Created by Rakesh Peela
 * Date: 25-Jan-2020
 * Time: 11:40 PM
 */

import {Button, Typography} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import LeftArrow from "react-dates/esm/components/LeftArrow";
import styled from "styled-components";
import {toTitleCase} from "../../../../../../../../../../../utils";
import {POSITION_ENUM} from "../../models/common";
import {MODULE_TYPES} from "../modules/moduleTypes";
import {TextHolder} from "./utils";

const getFlexDirection = (position) => {
    switch (position) {
        case POSITION_ENUM.BOTTOM:
            return "column-reverse";
        case POSITION_ENUM.TOP:
            return "column";
        case POSITION_ENUM.LEFT:
            return "row";
        case POSITION_ENUM.RIGHT:
            return "row-reverse";
        default:
            return "row";
    }
};

const getRotationByPosition = (position) => {
    switch (position) {
        case POSITION_ENUM.BOTTOM:
            return 0;
        case POSITION_ENUM.RIGHT:
            return -90;
        case POSITION_ENUM.TOP:
            return 180;
        case POSITION_ENUM.LEFT:
            return 90;
        default:
            return 0;
    }
};

const getSizeByShape = (shape) => {
    switch (shape) {
        case "circle":
            return {
                borderRadius: "50%",
                height: 360,
                width: 360
            };
        default:
            return {
                height: 540,
                width: 360
            }
    }
};

const RippleDiv = styled.div`
    position: absolute;
    animation: load-progress 1s infinite;
    /* background: ${props => props.color || "red"}; */
    background: white;
    animation-delay: 1s;
    z-index: 2;
    border-radius: ${props => props.shape === "circle" ? "50%" : "0"};
    @keyframes load-progress {
    0% {
        opacity: 1;
        width: 0px;
        height: 0px;
    }
    100% {
            opacity: 0;
            width: 360px;
            height: 360px
        }
    }
`;

class CoachmarkSim extends React.Component {
    render() {
        const {config, handleModuleSelection, textConfig, selectedModule} = this.props;
        const {position, bg_color, opacity, coach_mark_details} = config;
        const {type: coachmark_shape, has_ripple, has_arrow} = coach_mark_details;
        return (
            <div style={{
                height: 585,
                width: 585,
                borderRadius: 16,
                display: "flex",
                flexDirection: getFlexDirection(position),
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                // background: "aliceblue",
                // boxShadow: "0 3px 12px -6px rgba(0, 0, 0, 0.54)",
            }}>
                <div style={{
                    zIndex: 5
                }}>
                    <TextHolder
                        selected={selectedModule === MODULE_TYPES.DESCRIPTION_MODULE}
                        bgColor={bg_color}
                        config={textConfig}
                        handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.DESCRIPTION_MODULE, textConfig)}
                    >
                        Some Text Goes Here
                    </TextHolder>
                </div>

                {
                    has_arrow &&
                    <div style={{
                        transform: `rotate(${getRotationByPosition(position)}deg)`,
                        zIndex: 5
                    }}>
                        <ArrowUpwardIcon style={{color: "#ffffff"}}/>
                    </div>
                }

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: 5
                }}>
                    <Button
                        variant={"outlined"}
                        style={{
                            margin: 12,
                            background: "#ffffff"
                        }}
                    >
                        Gecko
                    </Button>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    ["margin" + toTitleCase(position)]: -52
                }}>
                    <div style={{
                        position: "absolute",
                        opacity: opacity,
                        background: bg_color,
                        zIndex: 1,
                        ...getSizeByShape(coachmark_shape)
                    }}/>
                    {has_ripple && <RippleDiv color={bg_color} shape={coachmark_shape}/>}
                </div>
            </div>
        );
    }
}

export default CoachmarkSim;