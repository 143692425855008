/**
 * Created by Rakesh Peela
 * Date: 20-Mar-2020
 * Time: 11:07 PM
 */
import {getApps} from "../../../Apps/actions";
import {
    GET_APP_SETTINGS,
    GET_APP_SETTINGS_FAILED,
    GET_APP_SETTINGS_PENDING,
    UPDATE_APP_SETTINGS, UPDATE_APP_SETTINGS_FAILED,
    UPDATE_APP_SETTINGS_PENDING
} from "./actionTypes";
import {getAppSettingsAPI, setTimeZoneForAppAPI, updateAppSettingsAPI, updateAppTZSettingsAPI} from "./api";

export const setTimeZoneForApp = (appId, tz) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [],
            payload: {
                promise: setTimeZoneForAppAPI(appId, getState().auth, tz).then((res) => {
                    return res;
                })
            },
            meta: {}
        })
    }
};

export const updateAppSettingsAction = (appId, appSettings) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                UPDATE_APP_SETTINGS_PENDING,
                UPDATE_APP_SETTINGS,
                UPDATE_APP_SETTINGS_FAILED
            ],
            payload: {
                promise: updateAppSettingsAPI(appId, getState().auth, appSettings)
                .then((res) => {
                    if (res) {
                        dispatch(getAppSettingsAction(appId));
                    }
                })
            },
            meta: {}
        })
    }
};

export const updateAppTZSettingsAction = (appId, data) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                UPDATE_APP_SETTINGS_PENDING,
                UPDATE_APP_SETTINGS,
                UPDATE_APP_SETTINGS_FAILED
            ],
            payload: {
                promise: updateAppTZSettingsAPI(appId, getState().auth, data)
                .then((res) => {
                    if (res) {
                        dispatch(getApps(appId));
                    }
                })
            },
            meta: {}
        })
    }
};


export const getAppSettingsAction = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                GET_APP_SETTINGS_PENDING,
                GET_APP_SETTINGS,
                GET_APP_SETTINGS_FAILED
            ],
            payload: {
                promise: getAppSettingsAPI(appId, getState().auth)
                .then((res) => {
                    return res;
                })
            },
            meta: {}
        })
    }
};