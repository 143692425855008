/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { AUTH_ERROR_ENUM } from '../../../../constants/EndPoints';
import Apxor from 'apxor';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        state: state //for listening all state changes
    };
}

class RequireAuth extends Component {

    componentWillMount() {
        const { session : { host, token , user={}} } = this.props;
        const email = user.email; //fix for un authenticated access

        // If this page is restricted, go to loginPage first.
        // (But pass on this page's path in order to redirect back upon login)
        if (!token) {
            const path = this.props.location.pathname;
            this.context.router.push(`/login?redirect=${path}&reason=${AUTH_ERROR_ENUM.UNAUTHORISED}`);
        }else{
            Apxor.setUserId(email);
            window.hj('identify', email, {email});
            ReactGA.set({ userId: email });
            ReactGA.set({ //FIXME: so this
                dimension1: "userEmail", //custom dimensions
                metric1: email
            });
        }
    }

    componentWillReceiveProps(nextProps){
        const { session: { host, token, host_pending } } = nextProps;
        if(!token){ //checks authentication and backend server host every time
            const { router } = this.context;
            const path = this.props.location.pathname;
            const { MISSING_HOST, MISSING_TOKEN } = AUTH_ERROR_ENUM;
            const reason = !token ? MISSING_TOKEN : MISSING_HOST;
            router.push(`/login?redirect=${path}&reason=${reason}`);
        }
    }

    render() {
        const { session, children } = this.props;
        if (session.token) {
            return children;
        }
        return null; //TODO: need auth fail message
    }
}

RequireAuth.propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
};

RequireAuth.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(RequireAuth);