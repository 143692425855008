/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../api';
import { makeDefaultQueryString } from '../../../../utils';

const LOGIN_API = "authenticate/customer";
const USER_EXISTENCE_API = "customer/exists";
const SIGN_UP_API = "register/customer";
const FORGOT_PASSWORD_API = "forgot-password/";
const RESET_PASSWORD_API = "reset-password/customer";

/**
 * Makes a  login Model for Authentication request
 * @param username
 * @param password
 * @returns {{userId: *, password: *}}
 */
const makeLoginModel = (username, password) => {
    return {
        userId: username,
        password: password
    };
};

/**
 *
 * @param email
 * @param password
 * @param token
 * @returns {{userId: *, token: *, password: *}}
 */
const makeResetPasswordModel = (email, password, token) => {
    return {
        userId: email,
        token: token,
        password: password
    };
};

/**
 *
 * @param name
 * @param username
 * @param password
 * @returns {{name: *, userId: *, password: *}}
 */
const makeSignUpModel = (name, username, password) => {
    return {
        name: name,
        userId: username,
        password: password
    };
};

/**
 *
 * @param username
 * @param password
 * @param auth {Object}
 * @returns {Promise}
 */
export function login(username, password, auth) {
    const config = {
        method: 'POST',
        auth: auth,
        body: JSON.stringify(makeLoginModel(username, password))
    };
    const onSuccessCallback = json => {
        return {
            token: '1234-5678-9101-1213', //FIXME: need token from server
            profile: {
                email: json.userId,
                name: json.name
            }
        };
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(LOGIN_API, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param email
 * @param auth
 * @returns {Promise}
 */
export function forgotPasswordAPI(email, auth) {
    const url = `${FORGOT_PASSWORD_API}${email}?host=${auth.host}`;
    const config = {
        method: 'GET',
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param email
 * @param password
 * @param token
 * @param auth
 * @returns {Promise}
 */
export function resetPasswordAPI(email, password, token, auth) {
    const config = {
        method: 'POST',
        auth: auth,
        body: JSON.stringify(makeResetPasswordModel(email, password, token))
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(RESET_PASSWORD_API, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param name
 * @param username
 * @param password
 * @param auth
 * @returns {Promise}
 */
export function signUp(name, username, password, auth) {
    const config = {
        method: 'POST',
        auth: auth, //FIXME: don't know what to do here
        body: JSON.stringify(makeSignUpModel(name, username, password))
    };
    const onSuccessCallback = json => {
        return {
            token: '1234-5678-9101-1213',
            profile: {
                email: json.userId,
                name: json.name,
            }
        };
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(SIGN_UP_API, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param host
 * @returns {Promise}
 */
export function userExistenceApi(auth, host) {
    const api = makeDefaultQueryString(USER_EXISTENCE_API, auth);
    const config = {
        method: 'GET',
        auth: {
            host: host
        }
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(api, config, onSuccessCallback, onFailureCallback);
}