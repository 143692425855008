/**
 * Created by Rakesh Peela
 * Date: 20-Mar-2020
 * Time: 11:07 PM
 */

import {Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import React from 'react';
import {connect} from 'react-redux';
import Select from "react-virtualized-select";
import 'react-virtualized-select/node_modules/react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import BetaChip from "../../../../../../../components/reusable/BetaChip";
import Loading from "../../../../../../../components/reusable/Loading";
import {updateHeading} from "../../../Navigation/actions";
import {getAppSettingsAction, setTimeZoneForApp, updateAppTZSettingsAction} from "./actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        appState: state.app,
        appSettings: state.appSettings,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        setTimeZone: (appId, tz) => dispatch(setTimeZoneForApp(appId, tz)),
        updateAppTZSettings: (appId, data) => dispatch(updateAppTZSettingsAction(appId, data)),
        getAppSettings: (appId) => dispatch(getAppSettingsAction(appId)),
    }
}

const getTZData = () => {
    let date = new Date();
    let timezones = momentTimezone.tz.names().map((tzItem, index) => {
        const zone = momentTimezone.tz.zone(tzItem);
        const offset = moment().utcOffset(zone.utcOffset(date));
        const formatted = zone.abbr(0) + " - " + tzItem;
        // + " (UTC " + offset.format("Z") + ")";
        return {
            id: tzItem,
            label: formatted,
            offset: zone.utcOffset(0)
        }
    })
    .map(option => {
        const rgx = /(^(\+|-)\d+\s- )/;
        const matches = option.label.match(rgx);
        if (matches) {
            const prefix = matches[0];
            option.label = option.label.split(prefix)[1];
        }
        return option;
    });
    return timezones;
};

class GeneralSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                timezone: props.appState.app.hasOwnProperty("settings") && props.appState.app.settings.timezone || "UTC"
            }
        }
    }

    componentDidMount() {
        const {params: {appId}} = this.props;
        this.props.updateHeading("General Settings");
    }

    render() {
        const {settings: {timezone: tz}, settings} = this.state;
        const {
            appState: {app: {settings: {timezone}}},
            appSettings: {progress}
        } = this.props;
        const {params: {appId}, updateAppTZSettings} = this.props;
        return (
            <section className="content">
                <Grid container justify={"center"}>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                <Grid container alignItems={"center"} spacing={16}>
                                    <Grid item xs={4}
                                          style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Typography variant={"h5"}> Timezone </Typography>
                                        <BetaChip withGutter/>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: "left"}}>
                                        <Typography variant={"subtitle2"}>
                                            Current Timezone:
                                        </Typography>
                                        <Typography variant={"h6"} style={{fontWeight: 600}}>
                                            {progress.get_settings_pending ?
                                                <Loading size={18}/>
                                                : (timezone ? timezone : "UTC")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            clearable={false}
                                            disabled={progress.get_settings_pending}
                                            labelKey={"label"}
                                            multi={false}
                                            searchable={true}
                                            simpleValue
                                            value={tz}
                                            valueKey={"value"}
                                            onChange={(value) => {
                                                this.setState({
                                                    settings: {
                                                        ...settings,
                                                        timezone: value
                                                    }
                                                })
                                            }}
                                            options={getTZData().map(option => {
                                                return {
                                                    label: option.label,
                                                    value: option.id
                                                }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            disabled={progress.get_settings_pending || (timezone === tz)}
                                            variant={"outlined"}
                                            onClick={() => {
                                                console.log("Timezone to update", tz);
                                                updateAppTZSettings(appId, {timezone: tz})
                                            }}
                                        >
                                            {
                                                progress.update_settings_pending &&
                                                <Loading size={12} style={{marginRight: 6}}/>
                                            } Update
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);