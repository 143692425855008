import Cookie from '../utils/Storage/Cookie';
import Logger from '../utils/Logger';
import {LOGIN_USER_SUCCESS, LOGOUT_USER} from "../containers/Root/Application/Auth/actionTypes";
const cookie = new Cookie(window, Logger);

function authMiddleware(key, login, logout) {
    return store => next => action => {
        const { type, payload } = action;
        if(type === login){
            try {
                cookie.set(key, btoa(JSON.stringify(payload) || ''), 7, true);
            }catch (e) {
                Logger.warn("Can't persist session");
            }
        }else if(type === logout){
            try {
                cookie.remove(key);
            }catch (e) {
                Logger.warn("Can't remove session from cookie");
            }
        }
        return next(action);
    };
}

export default (key) => authMiddleware(key, LOGIN_USER_SUCCESS, LOGOUT_USER);